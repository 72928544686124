import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  AnalyticsEvents,
  addPrefixToAnalyticProp,
  event,
  transformImageUrlToImageInteractionAnalyticsEvent,
  transformListProductToAnalyticsItem,
} from 'utils/analytics';

import { useSiteConfig } from './useSiteConfig';
import { usePageEventsContext } from './usePageEventsContext';

type ItemData = {
  itemIdEp?: string | undefined;
  itemNameEp?: string | undefined;
  itemListId: string | undefined;
  itemListName: string | undefined;
};

type ImageData = {
  imageType: string;
  imageNumber: string;
};

export type PromotionData = {
  creativeName?: string;
  creativeSlot?: string;
  promotionId?: string;
  promotionName?: string;
};

type FireSelectItemEvent = (
  itemListId: string,
  itemListName: string,
  prevItemEpData?: ItemData
) => void;

type GA4ContextType = {
  product?: any;
  itemListId?: string;
  itemListName?: string;
  imageType?: string;
  imageNumber?: string;
  itemPosition?: number;
  setProduct: Dispatch<SetStateAction<any>>;
  setItemPosition: Dispatch<SetStateAction<number | undefined>>;
  promotionData?: PromotionData;
  setPromotionData: Dispatch<SetStateAction<PromotionData>>;
  fireSelectItemEvent: FireSelectItemEvent;
  fireClickProductImage: (source: string) => void;
  fireEventGA4ForImageOpened: ({ itemListId, itemListName }: ItemData) => void;
  itemIdEpStored?: string;
  itemEpData?: ItemData;
};

export const initialState: GA4ContextType = {
  setProduct: () => null,
  setItemPosition: () => null,
  fireSelectItemEvent: () => null,
  fireClickProductImage: () => null,
  fireEventGA4ForImageOpened: () => null,
  setPromotionData: () => null,
};

/*
 This context is used to share common information between
 different components in order to trigger GA4 events
*/
export const GA4Context = React.createContext<GA4ContextType>(initialState);

export const GA4Provider = props => {
  const { currency } = useSiteConfig();
  const [product, setProduct] = useState<any>();
  const [itemPosition, setItemPosition] = useState<number>();
  const { pageviewEventHasFired } = usePageEventsContext();

  const [imageData, setImageData] = useState<ImageData>({
    imageType: '',
    imageNumber: '',
  });

  const [promotionData, setPromotionData] = useState<PromotionData>({});

  const [itemData, setItemData] = useState<ItemData>({
    itemIdEp: undefined,
    itemNameEp: undefined,
    itemListId: undefined,
    itemListName: undefined,
  });

  useEffect(() => {
    const handleImageInStorage = () => {
      const storedImage: ImageData = {
        imageType: window.sessionStorage.getItem('image_type') || '',
        imageNumber: window.sessionStorage.getItem('image_number') || '',
      };
      setImageData(prevData => ({
        ...prevData,
        ...storedImage,
      }));
    };
    window.addEventListener('imageInStorage', handleImageInStorage);
    return () => {
      window.removeEventListener('imageInStorage', handleImageInStorage);
    };
  }, []);

  const providerValue = React.useMemo(() => {
    const fireSelectItemEvent: FireSelectItemEvent = (
      itemListId,
      itemListName
    ) => {
      setItemData({
        itemListId,
        itemListName,
        itemNameEp: product?.name || product.productName,
        itemIdEp: product?.id || product.productId,
      });

      if (product) {
        const item = transformListProductToAnalyticsItem({
          product,
          currency: currency.code,
          quantity: 1,
          categories: { item_category: product.primaryCategoryId },
          itemListId,
          itemListName,
          index: itemPosition,
          promotion: product.promotions && product.promotions[0],
        });
        const itemUpdate = {
          ...item,
          creative_name: promotionData?.creativeName,
          creative_slot: promotionData?.creativeSlot,
          promotion_id: promotionData?.promotionId,
          promotion_name: promotionData?.promotionName,
        };
        event(AnalyticsEvents.GA4EC_ItemListClick, {
          event_name: AnalyticsEvents.SELECT_ITEM,
          ecommerce: {
            item_list_id: itemListId,
            item_list_name: itemListName,
            item_id_ep: itemData?.itemIdEp || product?.id || product.productId,
            item_name_ep:
              itemData?.itemNameEp || product?.name || product.productName,
            image_type: imageData.imageType,
            image_number:
              imageData.imageNumber !== 'unavailable'
                ? addPrefixToAnalyticProp(imageData.imageNumber)
                : undefined,
            items: [
              {
                ...itemUpdate,
              },
            ],
          },
        });
        setProduct(undefined);
      }
    };

    const fireClickProductImage = (source: string) => {
      const analyticsProductImage =
        transformImageUrlToImageInteractionAnalyticsEvent(source);
      window.sessionStorage.setItem(
        'image_type',
        analyticsProductImage.imageType
      );
      window.sessionStorage.setItem(
        'image_number',
        analyticsProductImage.imageNumber
      );
      window.dispatchEvent(new Event('imageInStorage'));
    };

    const fireEventGA4ForImageOpened = ({
      itemListId,
      itemListName,
    }: ItemData) => {
      if (pageviewEventHasFired) {
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.GA4_IMAGE_OPENED,
          event_params: {
            item_id_ep: itemListId,
            item_name_ep: itemListName,
          },
        });
      }
    };

    return {
      product,
      itemEpData: itemData,
      itemListId: itemData.itemListId,
      itemListName: itemData.itemListName,
      imageType: imageData.imageType,
      imageNumber: imageData.imageNumber,
      itemPosition,
      setProduct,
      setItemPosition,
      fireSelectItemEvent,
      fireClickProductImage,
      fireEventGA4ForImageOpened,
      promotionData,
      setPromotionData,
    };
  }, [
    product,
    itemPosition,
    currency.code,
    pageviewEventHasFired,
    imageData,
    setProduct,
    promotionData,
    setPromotionData,
    itemData,
  ]);

  return (
    <GA4Context.Provider value={providerValue}>
      {props.children}
    </GA4Context.Provider>
  );
};

export const useGA4Events = (): GA4ContextType => React.useContext(GA4Context);
