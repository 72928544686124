import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { IconNames } from '@cyber-cats/uds/icons';
import {
  ToastRoot,
  ToastTitle,
  ToastDescription,
  ToastProvider as UdsToastProvider,
  ToastViewport,
  ToastIcon,
} from '@cyber-cats/uds/elements';

import { getIsMobile } from 'utils/media';

const genRandomId = () => Math.random().toString(32).substring(2);

type ToastItem = {
  id: string;
  isOpen: boolean;
  variant: 'base' | 'success' | 'error' | 'info' | 'warning';
  title: ReactNode;
  description?: ReactNode;
  duration?: number;
  icon?: IconNames;
  size?: 'sm' | 'base' | 'md';
};

const Toast: FC<{
  value: ToastItem;
  onClose: (id: string) => void;
}> = ({ value, onClose }) => {
  return (
    <ToastRoot
      open={value.isOpen}
      onOpenChange={isOpen => !isOpen && onClose(value.id)}
      duration={value.duration}
      variant={value.variant}
      className={getIsMobile() ? '!w-full min-w-max' : ''}
    >
      <ToastTitle>{value.title}</ToastTitle>
      <ToastIcon icon={value.icon} />
      {value.description && (
        <ToastDescription>{value.description}</ToastDescription>
      )}
    </ToastRoot>
  );
};

type OpenToastParams = Omit<ToastItem, 'id' | 'isOpen'>;

const OpenToastContext = createContext<(params: OpenToastParams) => void>(
  () => null
);

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const openToast = useCallback((params: OpenToastParams) => {
    const id = genRandomId();
    setToasts(prev => [...prev, { id, isOpen: true, ...params }]);
  }, []);

  const closeToast = useCallback((id: string) => {
    setToasts(prev =>
      prev.map(value => (value.id === id ? { ...value, isOpen: false } : value))
    );
    setTimeout(() => {
      setToasts(prev => prev.filter(value => value.id !== id));
    }, 200);
  }, []);

  return (
    <OpenToastContext.Provider value={openToast}>
      <UdsToastProvider duration={5000}>
        {children}
        {toasts.map(value => (
          <Toast key={value.id} value={value} onClose={closeToast} />
        ))}
        <ToastViewport
          position={getIsMobile() ? 'bottom_middle' : 'bottom_end'}
        />
      </UdsToastProvider>
    </OpenToastContext.Provider>
  );
};

export function useToast() {
  return useContext(OpenToastContext);
}
