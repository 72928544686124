import { Heading } from '@cyber-cats/uds/elements';
import { ReactNode } from 'react';
import { tw } from 'twind';

type SearchOptionListProps = {
  id: string;
  dataTestId: string;
  heading: string;
  children: ReactNode;
  className?: string;
};

export const SearchOptionList = ({
  id,
  dataTestId,
  heading,
  children,
  className = 'flex flex-col',
}: SearchOptionListProps) => {
  return (
    <div data-test-id={dataTestId}>
      <Heading
        id={`${dataTestId}-heading`}
        dataTestId={`${dataTestId}-heading`}
        transform="uppercase"
        size="sm"
        asChild
      >
        <h2>{heading}</h2>
      </Heading>
      <ul
        aria-labelledby={`${id}-heading`}
        className={tw('w-full mt-6 gap-4', className)}
      >
        {children}
      </ul>
    </div>
  );
};
