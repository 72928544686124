import { QuiltLayout } from '@cyber-cats/uds/elements';

import { CategoryDocument } from './Category';
import { PromoTileDocument, PromoTileDocumentType } from './PromoTileDocument';

export type QuiltLayoutType = {
  gap: QuiltLayout['gap'];
  layout: QuiltLayout['layout'];
  limit: number;
};

export type QuiltDocumentType = {
  _id: string;
  _type: string;
  tileLayout: string;
  tileMediaRatio: string;
  mobile: QuiltLayoutType;
  tablet: QuiltLayoutType;
  desktop: QuiltLayoutType;
  inverted: boolean;
  contentJustify: 'start' | 'center' | 'end';
  items: PromoTileDocumentType[];
};

export const QuiltDocument = (field: string) => {
  return `${field} {
    _id,
    _type,
    tileLayout,
    tileMediaRatio,
    mobile,
    tablet,
    desktop,
    inverted,
    contentJustify,
    items[] -> {
      ${PromoTileDocument('_type == "PromoTile" =>')},
      ${CategoryDocument('_type == "category" =>')},
    },
  }`;
};
