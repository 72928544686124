import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { setDYContext, dyContextValues, dyContext } from 'utils/dynamicYield';
import { VariantProduct } from '__generated__/graphql';

import { useSiteConfig } from './useSiteConfig';

interface UseDynamicYieldContext {
  type?: dyContextValues;
  data?: Array<VariantProduct['ean']>;
  pause?: boolean;
}

export const useDynamicYieldContext = ({
  type,
  data,
  pause = false,
}: UseDynamicYieldContext = {}) => {
  const router = useRouter();
  const { staticFeatures, locale } = useSiteConfig();

  const dataEmpty = data?.every(element => !element);

  useEffect(() => {
    if (!staticFeatures?.injectDynamicYieldScripts) return;

    const isProductPage = router.asPath.includes('/pd');
    const isCartPage = router.asPath.includes('/cart');
    const isCategoryPage = !!router.query?.category;
    const isHomePage =
      router.pathname === '/[country]/[language]' && !isCategoryPage;
    const isOtherPage =
      !isProductPage && !isCartPage && !isCategoryPage && !isHomePage;

    if ((isProductPage || isCategoryPage) && dataEmpty) return;

    if (!pause && type) {
      isHomePage ? setDYContext(type) : setDYContext(type, data);
    }
    if (isOtherPage) {
      setDYContext(dyContext.OTHER);
    }
  }, [
    staticFeatures?.injectDynamicYieldScripts,
    type,
    dataEmpty,
    router.asPath,
    locale,
    router.query?.category,
    pause,
    data,
    router.pathname,
  ]);
};
