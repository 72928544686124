import { useEffect } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { SearchProductList } from 'ui/components/Search/SearchProductList';
import {
  SearchProductResult,
  SearchViewComponent,
} from 'ui/components/Search/types';
import { AnalyticsEvents, event, searchAnalyticsEvent } from 'utils/analytics';
import { trackSuggestEventInBloomreach } from 'utils/bloomreach';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useLocation } from 'hooks/useLocation';

import RecommendedProducts from './RecommendedProducts';

const SuggestedProducts: SearchViewComponent = ({
  suggestionsResult,
  setIsLoading,
  searchTerm,
  setSessionStorageData,
}) => {
  const t = useTranslate();
  const { countryCode } = useSiteConfig();
  const { location } = useLocation();

  useEffect(() => {
    setIsLoading(suggestionsResult?.fetching ?? false);
  }, [setIsLoading, suggestionsResult?.fetching]);

  const trackProductClick = (product: SearchProductResult) => {
    if (setSessionStorageData) {
      setSessionStorageData(t('suggestedProducts'), product.name);
      searchAnalyticsEvent('0');
    }

    event(AnalyticsEvents.SEARCH_SUGGESTED_PRODUCT_CLICK, {
      related_product: product.name,
    });
    trackSuggestEventInBloomreach({
      query: searchTerm,
      suggestion: product.name,
      siteCountryCode: countryCode,
      visitorCountryCode: location?.userCountry?.id,
    });
  };

  const suggestions = suggestionsResult?.data?.suggestions;
  if (!suggestions) return null;

  const suggestedProducts = suggestions.suggestedProductsSummaries ?? [];

  const products = suggestedProducts.map<SearchProductResult>(x => ({
    masterId: x.masterId,
    colorValue: x.colorValue,
    image: x.images[0],
    name: x.name,
    price: x.price,
    salePrice: x.salePrice,
  }));

  return products.length > 0 ? (
    <SearchProductList
      heading={t('suggestedProducts')}
      products={products}
      onClick={trackProductClick}
    />
  ) : (
    <RecommendedProducts setIsLoading={setIsLoading} />
  );
};

export default SuggestedProducts;
