// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/seo.ts

import { Seo } from '__generated__/graphql-sanity';
import { SanityImageObject } from 'groq/objects/SanityImageObject';
import { SanityVideoObject } from 'groq/objects/SanityVideoObject';

export type LocaleSeoType = {
  pageDescription: string;
  pageTitle: string;
  noIndex?: boolean;
};

export const LocaleSeo = (field: string) => `
  ${field} {
    'pageDescription': coalesce(
      pageDescriptions[$locale],
      pageDescriptions[$language],
      pageDescription,
      ''
    ),
    'pageTitle': coalesce(
      pageTitles[$locale],
      pageTitles[$language],
      pageTitle,
      ''
    ),
    'openGraphDescription': coalesce(
      openGraphDescription[$locale],
      openGraphDescription[$language],
      ''
    ),
    'openGraphTitle': coalesce(
      openGraphTitle[$locale],
      openGraphTitle[$language],
      ''
    ),
    'twitterDescription':coalesce(
      openGraphTitle[$locale],
      openGraphTitle[$language],
      ''
    ),
    'twitterTitle': coalesce(
      openGraphTitle[$locale],
      openGraphTitle[$language],
      ''
    ),
    ${SanityImageObject('twitterImageUrl')},
    ${SanityVideoObject('openGraphVideoUrl')},
    ${SanityImageObject('openGraphImageUrl')},
    openGraphType,
    noIndex,
  }
`;

export const LocaleSeoJSON = (args?: Partial<Seo>): Seo => ({
  pageDescription: '',
  pageTitle: '',
  ...args,
});
