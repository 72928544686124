import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { tw } from 'twind/css';
import { useRouter } from 'next/router';
import {
  Button,
  Icon,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from '@cyber-cats/uds/elements';

import {
  headerNavigationEvent,
  iconHeaderNavigationClickEvent,
  iconHeaderNavigationEvent,
} from 'utils/analytics';
import { isServer } from 'utils/constants';
import { Link } from 'ui/elements/Link';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useSticky } from 'hooks/useSticky';
import { useTranslate } from 'hooks/useTranslations';
import { useLiveChat } from 'hooks/useLiveChat';
import { useMainNavContext } from 'hooks/useMainNav';
import { SearchForm } from 'ui/components/Search/SearchForm';
import { groqPreviewInput } from 'hooks/useGroqQuery';
import { useAvailableNavItemFilter } from 'hooks/useAvailableNavItemFilter';

import { NavMenuBar } from './NavMenuBar';
import { CartLink } from './CartLink';
import { PhoneNavLink } from './PhoneNavLink';
import { WishlistLink } from './WishlistLink';

const QuickLinks = dynamic(() => import('ui/components/QuickLinks'), {
  ssr: false,
});

const MobileMenuScreen = dynamic(
  () => import('ui/components/MobileMenuScreen'),
  {
    ssr: false,
  }
);

const HomeButton = () => {
  return (
    <Link
      data-link-name="puma-logo"
      data-link-location="top-nav"
      data-test-id="main-nav-home-link"
      href="/"
      onClick={() => headerNavigationEvent({ parentNavigation: 'Home' })}
    >
      <Icon name="puma-logo" size="4xl" label="PUMA.com" />
    </Link>
  );
};

export const MainNav: React.FC<{
  minimal: boolean;
  mobileNavOffsetPx: number;
}> = props => {
  const t = useTranslate();
  const router = useRouter();

  const [linksHidden, setLinksHidden] = useState<boolean | undefined>(
    undefined
  );
  const hideNavChildren = !props.minimal && linksHidden === undefined;
  const { width, height } = useResizeObserver();
  const { stickyRef, stickyCss, combinedHeightByOrdinal } = useSticky(1);
  const { mobileMenuOpen, setMobileMenuOpen, navLinks } = useMainNavContext();
  const { openLiveChatWindow, liveChatEnabled, renderInMainNav } =
    useLiveChat();

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const availableNavItems = useAvailableNavItemFilter(
    navLinks,
    groqPreviewInput(router.query)
  );

  const categories = availableNavItems?.children || [];
  const userMenuQuicklinks = navLinks?.userMenuQuicklinks || [];

  const resetToggles = () => {
    setMobileMenuOpen(false);
    setDarkMode(true);
    setAccountMenuOpen(false);
    setMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setDarkMode(mobileMenuOpen);
  };

  const toggleAccountMenu = () => {
    setMobileMenuOpen(false);
    setAccountMenuOpen(!accountMenuOpen);
    setDarkMode(true);
  };

  useEffect(() => {
    resetToggles();
  }, [width, router]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id="nav-bar-sticky"
      className={tw(`relative z-30`, stickyCss)}
      ref={stickyRef}
    >
      <nav
        aria-label={t('mainNavLabel')}
        className={tw(
          props.minimal && 'mobile:px-4 tablet:px-6 desktop:px-8',
          darkMode
            ? 'bg-puma-black-100 text-white'
            : 'bg-white text-puma-black',
          hideNavChildren && 'children:invisible'
        )}
      >
        <div
          className={tw(
            'h-16 lg:h-20 flex items-center justify-between mx-auto overflow-y-visible',
            !darkMode && 'border-b',
            props.minimal
              ? 'max-w-screen-xl'
              : 'max-w-screen-3xl mobile:px-4 tablet:px-6 desktop:px-8'
          )}
          data-test-id="main-nav-bar"
        >
          <HomeButton />
          {!props.minimal && (
            <>
              <NavMenuBar
                categories={categories}
                setLinksHidden={setLinksHidden}
              />
              <div
                className={tw(
                  'flex items-center gap-2',
                  !hideNavChildren && 'ml-5'
                )}
              >
                <SearchForm darkMode={darkMode} />
                {liveChatEnabled && renderInMainNav && (
                  <Button
                    dataTestId="chat-button"
                    icon="chat"
                    onClick={() => openLiveChatWindow()}
                    mode="icon"
                    label=""
                  />
                )}
                <WishlistLink
                  darkMode={darkMode}
                  onClick={() => {
                    iconHeaderNavigationEvent('Wishlist');
                    iconHeaderNavigationClickEvent('Wishlist');
                  }}
                />
                <CartLink
                  darkMode={darkMode}
                  onClick={() => {
                    iconHeaderNavigationEvent('Cart');
                    iconHeaderNavigationClickEvent('Cart');
                  }}
                />
                {!linksHidden && (
                  <div className="relative">
                    <PopoverRoot
                      dataTestId="quicklinks-popout"
                      open={accountMenuOpen}
                      onOpenChange={setAccountMenuOpen}
                      autoFocus="content"
                      side="bottom"
                      align="end"
                      size="base"
                      offset={-20}
                    >
                      <PopoverTrigger>
                        <Button
                          id="account-button"
                          label={t('myAccount')}
                          icon="person"
                          variant="ghost"
                          mode="icon"
                          invert={darkMode}
                          onClick={() =>
                            iconHeaderNavigationClickEvent('My Account')
                          }
                        />
                      </PopoverTrigger>
                      <PopoverContent zIndex={40}>
                        <PopoverBody>
                          <QuickLinks
                            userMenuQuicklinks={userMenuQuicklinks}
                            hideMobileMenu={() => toggleAccountMenu()}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </PopoverRoot>
                  </div>
                )}
              </div>
              {linksHidden && (
                <button
                  onClick={() => toggleMobileMenu()}
                  data-test-id="account-button"
                  data-link-name="close"
                  data-link-location="mobile-nav"
                  className="flex items-center space-x-2 mt-1 ml-5"
                  type="button"
                >
                  {mobileMenuOpen ? (
                    <Icon name="close" size="xl" />
                  ) : (
                    <Icon name="menu" size="xl" />
                  )}
                  <span
                    data-test-id="main-nav-menu-icon"
                    className={tw([
                      'text-xs uppercase',
                      darkMode ? 'text-white' : 'text-black',
                    ])}
                  >
                    {t('menu')}
                  </span>
                </button>
              )}
            </>
          )}
          {props.minimal && !router.asPath.includes('showQuickRegister') && (
            <PhoneNavLink darkMode={darkMode} />
          )}
        </div>
        {mobileMenuOpen && (
          <div
            className="relative flex-col flex bg-white overflow-hidden"
            style={{
              height: isServer
                ? '100vh'
                : `${
                    window.innerHeight -
                    height -
                    props.mobileNavOffsetPx -
                    // Get the height of the top two (max) sticky banners
                    // ignoring the height of product filters since they
                    // are rendered below the mobile nav
                    (combinedHeightByOrdinal[1] || 0)
                  }px`,
            }}
          >
            <MobileMenuScreen
              userMenuQuicklinks={userMenuQuicklinks}
              isOpen={mobileMenuOpen}
              hideMobileMenu={() => toggleAccountMenu()}
            />
          </div>
        )}
      </nav>
    </div>
  );
};
