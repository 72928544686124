import React, { useRef } from 'react';
import { tw } from 'twind/css';

import { useListboxFocus } from 'hooks/a11y';
import { useMainNavContext } from 'hooks/useMainNav';
import { MainNavQueryType } from 'groq/queries/MainNavQuery';
import { MainNavItemType } from 'groq/shared/MainNavItem';
import { isServer } from 'utils/constants';

import { NavCategoryNavChildren } from './NavCategoryNavChildren';
import { NavCategorySubCategoriesInline } from './NavCategorySubCategoriesInline';
import { NavCategorySubCategoriesColumns } from './NavCategorySubCategoriesColumns';
import { MainNavSkeleton } from './MainNavSkeleton';

const extractCategoriesWithSubCategories = category =>
  (category?.children || []).filter(
    subCategory => subCategory.children && subCategory.children.length > 0
  );

const extractCategoriesWithoutSubCategories = category =>
  (category?.children || []).filter(
    subCategory => !subCategory.children || subCategory.children.length === 0
  );

export const NavCategorySubMenu = (props: {
  category: MainNavItemType;
  ariaSelected: boolean;
  ownerRef: React.RefObject<HTMLElement | null | undefined>;
  subMenuItems: MainNavQueryType;
}) => {
  const { ariaSelected, category, ownerRef, subMenuItems } = props;
  const categoryRef = useRef(null);
  const { selectedCategory, showSubMenu, handleCategoryClick } =
    useMainNavContext();

  const isMatchingCategory = selectedCategory === category.id;

  const subNavItems =
    subMenuItems?.children?.filter(item => item.id === category.id) || [];

  if (isServer) {
    return <></>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useListboxFocus(categoryRef, {
    isActive: true,
    ownerRef: ownerRef,
  });

  const categoriesWithSubCategories = extractCategoriesWithSubCategories(
    subNavItems[0]
  );

  const categoriesWithoutSubCategories = extractCategoriesWithoutSubCategories(
    subNavItems[0]
  );

  const cats =
    categoriesWithSubCategories.length === 0
      ? categoriesWithoutSubCategories
      : categoriesWithSubCategories;

  const keyboardNavigableMenuStyles = 'scale-y-100 opacity-100';
  const mouseNavigableMenuStyles = `transition-[transform,opacity] duration-0 ${
    showSubMenu && isMatchingCategory ? 'scale-y-100 z-10' : 'scale-y-0 z-0'
  }`;

  return (
    <div
      className={tw(
        'scale-y-100 opacity-100 absolute left-0 bottom-0 origin-top translate-y-full bg-white flex w-full text-puma-black-400 overflow-x-auto shadow-xl py-10 xl:py-12',
        ariaSelected ? keyboardNavigableMenuStyles : mouseNavigableMenuStyles
      )}
      onClick={() => handleCategoryClick(category.id)}
      data-test-id="secondary-nav-menu"
      ref={categoryRef}
    >
      {!subMenuItems?.children && <MainNavSkeleton />}
      <div className="flex w-full max-w-screen-3xl mx-auto px-10">
        {subNavItems && subNavItems[0]?.navChildren && (
          <NavCategoryNavChildren
            navChildren={subNavItems[0]?.navChildren}
            title={subNavItems[0]?.label}
          />
        )}
        {categoriesWithSubCategories.length === 0 ? (
          <NavCategorySubCategoriesInline
            categories={cats}
            title={category.label}
          />
        ) : (
          <NavCategorySubCategoriesColumns categories={cats} />
        )}
      </div>
    </div>
  );
};
