import NextLink from 'next/link';
import { tw, style, apply } from 'twind/style';
import React, { forwardRef } from 'react';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { AnalyticsEvents, event } from 'utils/analytics';
import { GaTrackData } from 'hooks/usePromotionView';
import { useCookieSettings } from 'hooks/useCookieSettings';
import { useGA4Events } from 'hooks/useGA4Events';
import { Ga4Data, usePromotionSelect } from 'hooks/usePromotionSelect';
export interface CTA {
  link: string;
  title: string;
  id: string;
}

const link = style({
  base: 'bg-transparent text-current text-base no-underline',
  variants: {
    variant: {
      black: 'text-puma-black',
      white: 'text-white',
      primary: 'text-puma-gold',
      secondary: 'text-puma-silver',
    },
    underlined: {
      true: 'bg-transparent border-b-1 border-current text-sm uppercase font-bold leading-tight',
    },
    size: {
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    },
    disabled: {
      true: 'opacity-60 pointer-events-none',
    },
  },
});

const button = style({
  base: 'block px-5 py-2 whitespace-nowrap border-1 uppercase font-bold tracking-wide text-center flex items-center justify-center',
  variants: {
    variant: {
      black: 'bg-puma-black border-puma-black text-white',
      white: 'bg-white border-white text-puma-black',
      primary: 'bg-puma-gold border-puma-gold text-white',
      secondary: 'bg-puma-silver border-puma-silver text-white',
    },
    height: {
      tall: 'h-14',
    },
    outlined: {
      true: 'bg-transparent',
    },
    underlined: {
      true: 'px-0 py-px bg-transparent border-0 border-b-1 border-current text-sm uppercase font-bold rounded-none leading-tight',
    },
    disabled: {
      true: 'opacity-40 pointer-events-none',
    },
  },
  matches: [
    { variant: 'black', outlined: true, use: 'text-puma-black' },
    { variant: 'white', outlined: true, use: 'text-white' },
    { variant: 'primary', outlined: true, use: 'text-puma-gold' },
    { variant: 'secondary', outlined: true, use: 'text-puma-silver' },
  ],
});

type ButtonStyleProps = Parameters<typeof button>[0];
type LinkStyleProps = Parameters<typeof link>[0];
type LinkTagWithNoHref = Omit<React.ComponentProps<'a'>, 'href'>;
export type LinkProps = LinkTagWithNoHref &
  ButtonStyleProps &
  LinkStyleProps & {
    href: string | null;
    dataTestId?: string;
    dataLinkLoc?: string;
    query?: string | undefined | null;
    newtab?: boolean;
    popup?: boolean;
    disabled?: boolean;
    height?: 'tall';
    action?: string;
    as?: 'button' | 'link';
  } & { gaBannerData?: GaTrackData; ga4Data?: Ga4Data };

const TRIGGER_COOKIE_MANAGEMENT_BAR = 'triggerCookieManagementBar';

export const Link = forwardRef((props: LinkProps, ref: any) => {
  const {
    href,
    newtab,
    popup,
    query,
    as,
    dataTestId,
    dataLinkLoc,
    className,
    disabled,
    variant,
    outlined,
    height,
    underlined,
    size,
    gaBannerData,
    ga4Data,
    action,
    ...restProps
  } = props;

  const { setPromotionData } = useGA4Events();

  const { setShowCookieSettings } = useCookieSettings();
  const newtabProps = newtab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  const { localizeUrlPath } = useSiteConfig();
  const isAbsolute =
    href && (href.includes('https:') || href.includes('http:'));

  const getHref = () => {
    if (!href) return '/';
    if (isAbsolute) return href;
    return `${localizeUrlPath(href)}${query || ''}`;
  };

  const { promotionSelectEvent } = usePromotionSelect();

  const popupClickHandler = e => {
    e.stopPropagation();
    if (action === TRIGGER_COOKIE_MANAGEMENT_BAR) {
      e.preventDefault();
      setShowCookieSettings(true);
    }
    if (!href) return e.preventDefault();
    if (gaBannerData) {
      event(AnalyticsEvents.PROMOTION_CLICK, {
        ecommerce: {
          promoClick: {
            promotions: [
              {
                ...gaBannerData,
                'cta-click': !!props.children
                  ? `Button: ${props.children}`
                  : 'Banner Clicked',
              },
            ],
          },
        },
      });
      setPromotionData({
        creativeName: gaBannerData.creative,
        creativeSlot: gaBannerData.position,
        promotionId: gaBannerData.id,
        promotionName: gaBannerData.name,
      });
    }
    if (ga4Data) {
      promotionSelectEvent({
        ...ga4Data,
        cta_click: ga4Data.cta_click && `Button: ${ga4Data.cta_click}`,
      });
    }
    if (popup) {
      e.preventDefault();
      return window.open(
        getHref(),
        '',
        `
        scrollbars=yes,
        menubar=no,
        status=no,
        location=no,
        toolbar=no,
        width=750,
        height=780,
        top=${(window.innerHeight - 780) / 2},
        left=${(window.innerWidth - 750) / 2}
        `
          .replace(/\\n/g, '')
          .trim()
      );
    }
  };

  return isAbsolute ? (
    <a
      href={getHref()}
      onClick={popupClickHandler}
      aria-disabled={disabled || href === null}
      data-test-id={dataTestId}
      ref={ref}
      className={tw(
        apply(
          as === 'button'
            ? button({ variant, outlined, underlined, disabled, height })
            : link({ variant, underlined, size, disabled })
        ),
        href === null && 'cursor-default',
        className
      )}
      {...newtabProps}
      {...restProps}
    />
  ) : (
    <NextLink href={getHref()} prefetch={false}>
      <a
        onClick={popupClickHandler}
        aria-disabled={disabled || href === null}
        data-test-id={dataTestId}
        data-link-loc={dataLinkLoc}
        ref={ref}
        className={tw(
          apply(
            as === 'button'
              ? button({ variant, outlined, underlined, disabled, height })
              : link({ variant, underlined, size, disabled })
          ),
          !href && 'cursor-default',
          className
        )}
        {...newtabProps}
        {...restProps}
      />
    </NextLink>
  );
});

Link.displayName = 'Link';
