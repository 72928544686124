import { NextPageContext } from 'next';
import { parseCookies } from 'nookies';
import { DeepPartial } from 'strict-obj';

import { Order, AddItemsToCartMutation, CartItem } from '__generated__/graphql';

import { getFinalProductPrice } from './products';
const OPTANON_CONSENT_KEY = 'OptanonConsent';
const MARKETING_COOKIES = 'C0004:1';

interface Currency {
  code: string;
}

export const dyEvents = {
  ADD_TO_CART: 'Add To Cart',
  ADD_TO_WISHLIST: 'Add to Wishlist',
  CHANGE_ATRIBUTE: 'Change Attribute',
  FILTER_ITEMS: 'Filter Items',
  KEYWORD_SEARCH: 'Keyword Search',
  LOGIN: 'Login',
  PURCHASE: 'Purchase',
  SIGNUP: 'Signup',
  SORT_ITEMS: 'Sort Items',
  SYNC_CART: 'Sync cart',
  CHECKOUT_STARTED: 'checkout_started',
  CHECKOUT_SHIPPING: 'Checkout Step One - Shipping',
  CHECKOUT_PAYMENT: 'Checkout Step Two - Payment',
} as const;

type dyEventKeys = keyof typeof dyEvents;

export type dyEventValues = (typeof dyEvents)[dyEventKeys];

export const dyContext = {
  CART: 'CART',
  CATEGORY: 'CATEGORY',
  HOMEPAGE: 'HOMEPAGE',
  OTHER: 'OTHER',
  PRODUCT: 'PRODUCT',
} as const;

type dyContextKeys = keyof typeof dyContext;
export type dyContextValues = (typeof dyContext)[dyContextKeys];

type ProductCartItem =
  AddItemsToCartMutation['addItemsToCart']['products'][0] & {
    __typename: 'ProductCartItem';
  };

export const dyEvent = (name: dyEventValues, properties?: any) => {
  if (window.DY) window.DY.API?.('event', { name, properties });
};

export const setDYContext = (type: dyContextValues, data?: any) => {
  // DY is to be defined here
  window.DY!.recommendationContext = {
    type,
    ...(data && { data }),
  };
};

export const setDYCookieConsent = (): boolean => {
  const hasAcceptedCookie = isConsentAccepted();

  window.DY!.userActiveConsent = { accepted: hasAcceptedCookie };
  window.DYO?.ActiveConsent?.updateConsentAcceptedStatus(hasAcceptedCookie);

  return hasAcceptedCookie;
};

export const dyAddToCartEvent = (
  cart: AddItemsToCartMutation['addItemsToCart'],
  currencyCode: Currency['code']
) => {
  const productCartItems = cart?.products.filter(
    p => p!.__typename === 'ProductCartItem'
  ) as Array<ProductCartItem>;
  if (productCartItems.length) {
    const lastProductIndex: number = productCartItems.length;
    const lastProduct = productCartItems[lastProductIndex - 1];
    const formattedCart = productCartItems.map((p: ProductCartItem) => {
      return {
        productId: p.product.ean,
        quantity: p.quantity,
        itemPrice: getFinalProductPrice(p.product),
      };
    });

    const formatedProps = {
      dyType: 'add-to-cart-v1',
      value: getFinalProductPrice(lastProduct?.product) * lastProduct?.quantity,
      currency: currencyCode,
      productId: lastProduct?.product.ean,
      quantity: lastProduct?.quantity,
      size: lastProduct?.product.size.label,
      cart: formattedCart,
    };
    dyEvent(dyEvents.ADD_TO_CART, formatedProps);
  }
};

export const dyPurchaseEvent = (
  order: Partial<Order>,
  currency: Currency
): void => {
  if ('products' in order) {
    const cart = order
      .products!.map(item => {
        if ('product' in item && item.product) {
          const { product, quantity } = item;
          return {
            productId: product.id,
            quantity,
            itemPrice:
              product.productPrice?.promotionPrice ??
              product.productPrice?.salePrice,
            size: product.size.label,
          };
        }
        return null;
      })
      .filter(item => item !== null);

    dyEvent(dyEvents.PURCHASE, {
      uniqueTransactionId: order.orderNo,
      dyType: 'purchase-v1',
      value: order.estimatedTotal,
      currency: currency.code,
      cart,
    });
  }
};

export const dyLoginEvent = (email?: string | undefined | null) => {
  if (email) {
    const hashedEmail = window.DYO?.dyhash?.sha256(email.toLowerCase());
    const properties = {
      dyType: 'login-v1',
      hashedEmail,
    };
    dyEvent(dyEvents.LOGIN, properties);
  }
};

export const dySignUpEvent = (email: string) => {
  const formattedProps = {
    dyType: 'signup-v1',
    hashedEmail: window.DYO?.dyhash?.sha256(email.toLowerCase()),
  };
  dyEvent(dyEvents.SIGNUP, formattedProps);
};

export const dyFilterEvent = (filterType: string, filter: string) => {
  dyEvent(dyEvents.FILTER_ITEMS, {
    dyType: 'filter-items-v1',
    filterType,
    filterStringValue: filter,
  });
};

export const dyAddToWishlistEvent = (productId: string, size: string): void => {
  dyEvent(dyEvents.ADD_TO_WISHLIST, {
    dyType: 'add-to-wishlist-v1',
    productId,
    size,
  });
};

const sortEventPayloads: Record<
  string,
  { sortBy: string; sortOrder?: string }
> = {
  'price-high-to-low': {
    sortBy: 'PRICE',
    sortOrder: 'DESC',
  },
  'price-desc': {
    sortBy: 'PRICE',
    sortOrder: 'DESC',
  },
  'price-low-to-high': {
    sortBy: 'PRICE',
    sortOrder: 'ASC',
  },
  'price-asc': {
    sortBy: 'PRICE',
    sortOrder: 'ASC',
  },
  newest: {
    sortBy: 'AGE',
  },
  'new-arrivals': {
    sortBy: 'AGE',
  },
  'top-sellers': {
    sortBy: 'POPULARITY',
  },
  MostPopular: {
    sortBy: 'POPULARITY',
  },
};

export const dySortItemsEvent = (sort: string): void => {
  const payload = sortEventPayloads[sort];

  if (payload) {
    dyEvent(dyEvents.SORT_ITEMS, {
      dyType: 'sort-items-v1',
      ...payload,
    });
  }
};

export const dyKeywordSearchEvent = (keywords: string): void => {
  dyEvent(dyEvents.KEYWORD_SEARCH, {
    dyType: 'keyword-search-v1',
    keywords,
  });
};

export const dyChangeProductAttribute = (attributeType, attributeValue) => {
  dyEvent(dyEvents.CHANGE_ATRIBUTE, {
    name: 'Change Attribute',
    properties: {
      dyType: 'change-attr-v1',
      attributeType,
      attributeValue,
    },
  });
};

export const dySyncCartEvent = (
  cartItems: DeepPartial<CartItem>[],
  currency: Currency
) => {
  const currentCart = cartItems
    .filter((item): item is ProductCartItem => {
      return item?.__typename === 'ProductCartItem';
    })
    .map(cartItem => ({
      productId: cartItem.product.id,
      quantity: cartItem.quantity,
      itemPrice: cartItem.product.salePrice ?? undefined,
    }));

  dyEvent(dyEvents.SYNC_CART, {
    dyType: 'sync-cart-v1',
    currency: currency.code,
    cart: currentCart,
  });
};

export const dyCheckoutStartedEvent = () => {
  dyEvent(dyEvents.CHECKOUT_STARTED);
};

export const dyCheckoutShippingEvent = () => {
  dyEvent(dyEvents.CHECKOUT_SHIPPING);
};

export const dyCheckoutPaymentEvent = () => {
  dyEvent(dyEvents.CHECKOUT_PAYMENT);
};

export const isConsentAccepted = (ctx?: NextPageContext): boolean => {
  const params = ctx ? { req: ctx.req } : null;
  const cookie = parseCookies(params)[OPTANON_CONSENT_KEY];

  return !!cookie?.includes(MARKETING_COOKIES);
};
