import { Maybe } from 'utils/types';

type ImageAssetMetaDataDimensions = {
  height: number;
  width: number;
};

type ImageAssetMetaData = {
  dimensions: ImageAssetMetaDataDimensions;
  lqip?: Maybe<string>;
};

export type ImageAssetDocumentType = {
  _id: string;
  extension: string;
  metadata: ImageAssetMetaData;
  path: string;
  url: string;
};

export const ImageAssetDocument = field => `
  ${field} {
    _id,
    url,
    path,
    metadata {
      lqip,
      dimensions {
        width,
        height,
      }
    },
    extension,
  }
`;
