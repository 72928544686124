import React from 'react';

import { CartItem, WishListItem } from '__generated__/graphql';

type MiniCartItem = WishListItem | CartItem;

type MiniCartProducts = MiniCartItem[];

type MiniCartContextType = {
  products?: MiniCartProducts;
  openMiniCart: (products: WishListItem | CartItem | MiniCartProducts) => void;
  closeMiniCart: () => void;
  isMiniCartVisible: boolean;
  mixedCartNotAllowed: boolean;
  setMixedCartNotAllowed: (condition: boolean) => void;
};

export const MiniCartContext = React.createContext<MiniCartContextType>({
  products: undefined,
  openMiniCart: () => null,
  closeMiniCart: () => null,
  isMiniCartVisible: false,
  mixedCartNotAllowed: false,
  setMixedCartNotAllowed: () => null,
});

export const MiniCartProvider = props => {
  const [products, setProducts] = React.useState<MiniCartProducts>();
  const [isMiniCartVisible, setIsMiniCartVisible] = React.useState(false);
  const [mixedCartNotAllowed, setMixedCartNotAllowed] = React.useState(false);

  const openMiniCart = React.useCallback(
    (products: MiniCartItem | MiniCartProducts) => {
      const productsArray = Array.isArray(products) ? products : [products];

      if (productsArray.length) {
        setProducts(productsArray);
        setIsMiniCartVisible(true);
      }
    },
    []
  );

  const closeMiniCart = React.useCallback(() => {
    setProducts(undefined);
    setIsMiniCartVisible(false);
  }, []);

  const providerValue = React.useMemo(() => {
    return {
      products,
      openMiniCart,
      closeMiniCart,
      isMiniCartVisible,
      mixedCartNotAllowed,
      setMixedCartNotAllowed,
    };
  }, [
    products,
    openMiniCart,
    closeMiniCart,
    isMiniCartVisible,
    mixedCartNotAllowed,
    setMixedCartNotAllowed,
  ]);

  return (
    <MiniCartContext.Provider value={providerValue}>
      {props.children}
    </MiniCartContext.Provider>
  );
};

export const useMiniCart = (): MiniCartContextType =>
  React.useContext(MiniCartContext);
