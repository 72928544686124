import { Maybe } from 'utils/types';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import { Puid, HorizontalAlignment } from 'groq/global-types';

import {
  CountdownClockDocument,
  CountdownClockDocumentType,
} from './CountdownClockDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/SplitHero.ts

export type SplitHeroDocumentType = {
  id: string;
  _id: string;
  _type?: Maybe<string>;
  backgroundColor?: Maybe<string>;
  backgroundImage: SanityImageObjectType;
  copy?: Maybe<LocaleStringType>;
  countdownClock?: Maybe<CountdownClockDocumentType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  footnote?: Maybe<LocaleStringType>;
  header?: Maybe<LocaleStringType>;
  horizontalAlignment?: Maybe<HorizontalAlignment>;
  layout?: Maybe<HorizontalAlignment>;
  logo?: Maybe<SanityImageObjectType>;
  mobileBackgroundImage?: Maybe<SanityImageObjectType>;
  puid?: Maybe<Puid>;
  textColor?: Maybe<string>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
};

export const SplitHeroDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    ${SanityImageObject('logo')},
    ${SanityImageObject('backgroundImage')},
    ${SanityImageObject('mobileBackgroundImage')},
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${LocaleString('footnote')},
    ${CallToActionObject('ctas[]')},
    ${CallToActionObject('link')},
    linkType,
    ${CountdownClockDocument('countdownClock->')},
    layout,
    horizontalAlignment,
    backgroundColor,
    textColor,
    puid,
    availableFrom,
    availableTo
  }
`;
