import { Maybe } from 'utils/types';
import { SanityImageObject } from 'groq/objects/SanityImageObject';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/AdditionalContentSection.ts

type TextType = {
  _key: string;
  _type: string;
  children: Array<{
    _key: string;
    _type: string;
    marks: Array<string | null>;
    text: string;
  }>;
  markDefs?: Array<{
    _key?: string;
    _type?: string;
    href?: string;
    newTab?: Maybe<string>;
  }>;
  style?: Maybe<string>;
  level?: Maybe<string>;
  listItem?: Maybe<string>;
};

export type AdditionalContentSectionDocumentType = {
  _id: string;
  _type: string;
  text?: Array<TextType>;
};

export const AdditionalContentSectionDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    text[] {
      _type == "block" => {
        _type,
        _key,
        children[] {
          _type,
          _key,
          text,
          marks,
        },
        markDefs[] {
          _key,
          _type,
          href,
          newTab
        },
        style,
        level,
        listItem,
      },
      _type == "image" => {
        ${SanityImageObject('image')},
      }
    }
  }
`;
