import { SelectOptions } from '@cyber-cats/uds/elements';

import { OrderStatusCode } from '__generated__/graphql';

/** These values must be kept in sync with {@link https://github.com/cyber-cats/puma-gql/blob/main/src/utils/constants.ts} */
export enum PaymentMethod {
  ADYEN_COMPONENT = 'AdyenComponent',
  ADYEN_PAYPAL = 'paypal',
  AFTERPAY = 'AFTERPAY_PBI',
  AMAZON_PAY = 'AMAZON_PAY',
  AMBASSADOR_CARD = 'AMBCARD',
  APPLE_PAY = 'DW_APPLE_PAY',
  COD = 'COD',
  CREDIT_CARD = 'CREDIT_CARD',
  GIFT_CARD = 'GIFT_CARD',
  GOOGLE_PAY = 'DW_GOOGLE_PAY',
  KLARNA = 'klarna',
  KLARNA_ACCOUNT = 'klarna_account',
  KLARNA_PAYMENTS = 'KLARNA_PAYMENTS',
  NET_BANKING_DEPRECATED = 'billdesk_online', // Will be deprecated: https://docs.adyen.com/online-payments/payments-india-compliance-guide/data-localization-in-india/phase-1 .
  ONLINE_BANKING_IN = 'onlinebanking_IN', // This one will be used from 15th of Dec, 2022.
  PAY_PAY = 'PAY_PAY',
  PAYPAL = 'PayPal',
  PAYTM = 'paytm',
  SCHEME = 'scheme',
  STORE_CREDIT = 'GIFT_CERTIFICATE',
  UPI = 'billdesk_upi',
  UPI_COLLECT = 'upi_collect',
  WALLET = 'billdesk_wallet',
  ZIP_PAY = 'zip',
  WECHAT_PAY = 'wechatpayWeb',
  AFTERPAY_TOUCH = 'afterpaytouch',
  ALIPAY = 'alipay',
  RAZORPAY = 'RAZORPAY',
}

export enum PaymentMethodExtra {
  GIFT_CARD = 'GIFT_CARD',
  GMO = 'GMO',
}

/**
 * @remark Creates a union of the literal values from the OcapiConst enum.
 * Equivalent to `type PAYMENTS_UNION = typeof PAYPAY | typeof PAYTM | etc`
 */
export type PAYMENTS_UNION = `${PaymentMethod}`;

export const isServer = typeof window === 'undefined';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export enum SUPPORTED_LOCALE {
  DE_DE = 'de-DE',
  EN_AU = 'en-AU',
  EN_CA = 'en-CA',
  EN_DE = 'en-DE',
  EN_GB = 'en-GB',
  EN_IN = 'en-IN',
  EN_NZ = 'en-NZ',
  EN_US = 'en-US',
  ES_US = 'es-US',
  FR_CA = 'fr-CA',
  JA_JP = 'ja-JP',
}

export enum SUPPORTED_LANGUAGE {
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  JA = 'ja',
}

export enum SUPPORTED_COUNTRY {
  AU = 'AU',
  CA = 'CA',
  DE = 'DE',
  GB = 'GB',
  IN = 'IN',
  JP = 'JP',
  NZ = 'NZ',
  US = 'US',
}

export enum UNITED_STATES_ARMED_FORCES {
  AF = 'AF',
  AA = 'AA',
  AC = 'AC',
  AE = 'AE',
  AM = 'AM',
  AP = 'AP',
}

export enum UNITED_STATES_TERRITORY {
  PR = 'PR',
  GU = 'GU',
  VI = 'VI',
  MP = 'MP',
  AS = 'AS',
  // UM doesn't have postal codes
}

export enum SUPPORTED_PAYMENT_TYPES {
  AdyenKlarna = 'Adyen Klarna',
  Afterpay = 'Afterpay',
  AmazonPay = 'Amazon Pay',
  AmbassadorCard = 'Ambassador Card',
  CashOnDelivery = 'Cash on Delivery',
  CreditCard = 'Credit Card',
  GiftCard = 'Gift Card',
  Klarna = 'Klarna',
  OnlineBanking = 'NetBanking',
  PayPal = 'PayPal',
  PayPay = 'PayPay',
  PayTM = 'PayTM',
  UPI = 'UPI',
  Wallet = 'Wallet',
}

export const GUAM = 'GU';

export const EU_SHIPPING_COUNTRIES_OPTIONS: SelectOptions = [
  { label: 'Austria', value: 'AT' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Greece', value: 'GR' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Norway', value: 'NO' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Romania', value: 'RO' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
];

export const SHIPPING_LOOKUP_CONSTRAINTS = {
  au: [SUPPORTED_COUNTRY.AU],
  ca: [SUPPORTED_COUNTRY.CA],
  de: [SUPPORTED_COUNTRY.DE],
  gb: [SUPPORTED_COUNTRY.GB],
  in: [SUPPORTED_COUNTRY.IN],
  jp: [SUPPORTED_COUNTRY.JP],
  nz: [SUPPORTED_COUNTRY.NZ],
  us: [SUPPORTED_COUNTRY.US, GUAM],
};

export const BILLING_LOOKUP_CONSTRAINTS = {
  au: [SUPPORTED_COUNTRY.AU],
  ca: [SUPPORTED_COUNTRY.US, SUPPORTED_COUNTRY.CA, GUAM],
  de: [SUPPORTED_COUNTRY.DE],
  gb: [SUPPORTED_COUNTRY.GB],
  in: [SUPPORTED_COUNTRY.IN],
  jp: [SUPPORTED_COUNTRY.JP],
  nz: [SUPPORTED_COUNTRY.NZ],
  us: [SUPPORTED_COUNTRY.US, SUPPORTED_COUNTRY.CA, GUAM],
};

// According to current policy for returns
export const MAX_DAYS_FOR_RETURN = 45;

// Existing app uses a 55-word limit to cut the text
export const WORD_COUNT_FOR_TEXT_CUT = 55;

// Possible values for basketInStockStatus:
// - INSTOCK (all products in the Basket are in -stock)
// - PREORDER (all products are preorder with the same in -stock date)
// - MIXED (any combination of product availabilities, including preorder) products with different in -stock dates
export enum BASKET_STOCK_STATUS {
  PREORDER = 'PREORDER',
  MIXED = 'MIXED',
  INSTOCK = 'INSTOCK',
}

export const INPROGRESS_ORDER_STATUS_CODES = [
  OrderStatusCode.BeingProcessed,
  OrderStatusCode.New,
  OrderStatusCode.Created,
  OrderStatusCode.Open,
] as const;

export const CARD_TYPES = {
  'american express': 'americanExpress',
  'master card': 'master',
  'diners club': 'diners',
  jcb: 'JCB',
};

export enum PAYMENT_METHOD_SESSION_STORAGE_ENTRY {
  PayPal = 'paypal-order-number',
  Afterpay = 'afterpay-order-number',
  CreditCard = 'credit-card-order-number',
  AdyenKlarna = 'klarna-order-number',
  UPI = 'upi-order-number',
  Wallet = 'wallet-order-number',
  OnlineBanking = 'onlineBanking-order-number',
  PayTM = 'paytm-order-number',
  UpiCollect = 'adyen-upi-collect-order-number',
  PayPay = 'paypay-order-number',
  COD = 'cod-order-number',
  ApplePay = 'apple-pay-order-number',
  GooglePay = 'google-pay-order-number',
  WechatPay = 'wechat-pay-order-number',
  ZipPay = 'zip-pay-order-number',
  Alipay = 'alipay-order-number',
  Razorpay = 'razorpay-order-number',
  Klarna = 'klarna-payments-order-number',
}

export enum OpluxFraudDetectionMethod {
  DETECT = 'Detect',
  UPDATE = 'Update',
}

export const REFERRER_CATEGORY = 'referrer-category';
export const REFERRER_TYPE = 'referrer-type';
export enum REFERRER_TYPE_VAL {
  AutomatedComparison = 'automatedComparison',
  Carousel = 'carousel',
  Comparison = 'comparison',
  Stylitics = 'stylitics',
}

export const COOKIE_BANNER_ACKNOWLEDGED_EVENT_NAME = 'accept-cookies';

// FitAnalytics Fit Finder tool is place in the DOM directly before the element
// with this attribute.  See docs/third-party-integrations/fit-analytics.md.
export const FITANALYTICS_FIT_FINDER_PLACEMENT = {
  ['data-test-id']: 'size-guide-btn',
};

// FitAnalytics uses the element with this attribute to determine if the page
// has loaded and the category. See docs/third-party-integrations/fit-analytics.md
export const FITANALYTICS_IS_LOADED_INDICATOR = {
  id: 'breadcrumb',
};

export enum MaxAgeExpiration {
  DAILY = 86400, // 24 * 60 * 60
  WEEKLY = 604800, // 7 * 24 * 60 * 60
  MONTHLY = 2592000, // 30 * 24 * 60 * 60,
}

export const CART_ERROR = {
  LONG_TERM_PAYMENT_ERROR: 'LONG_TERM_PAYMENT_ERROR',
  LONG_TERM_PAYMENT_EMPTY: 'LONG_TERM_PAYMENT_EMPTY',
};

export enum PaymentMethodsGA4Analytics {
  AFTERPAY = 'afterpay',
  ALIPAY = 'alipay',
  AMAZON_PAY = 'amazon pay',
  AMBASSADOR_CARD = 'ambassador card',
  APPLE_PAY = 'apple pay',
  CASH_ON_DELIVERY = 'cash on delivery',
  CREDIT_CARD = 'credit card',
  GIFT_CARD = 'gift card',
  GOOGLE_PAY = 'google pay',
  KLARNA = 'klarna',
  NET_BANKING = 'netbanking',
  PAY_PAY = 'paypay',
  PAYPAL = 'paypal',
  UPI = 'upi',
  WALLETS = 'wallets',
  ZIP_PAY = 'zip',
  WECHAT_PAY = 'wechat',
  ADYEN_COMPONENT = 'adyen component',
  PAYTM = 'paytm',
  SCHEME = 'scheme',
  STORE_CREDIT = 'store credit',
  UPI_COLLECT = 'upi collect',
  AFTERPAY_TOUCH = 'afterpay touch',
  RAZORPAY = 'razorpay',
  KLARNA_ACCOUNT = 'klarna account',
  KLARNA_PAYMENTS = 'klarna payments',
}

export const PRODUCT_POSITIONS_LOCAL_STORAGE_KEY = 'productsPositions';

export const SLOT_LINK_TYPE = {
  CTA: 'ctas',
  LINK: 'link',
} as const;

type LinkTypeKeys = keyof typeof SLOT_LINK_TYPE;

export type LinkType = (typeof SLOT_LINK_TYPE)[LinkTypeKeys];

// ─────────────────────────────────────────────────────────────────────────────
export const SCROLL_GA4_EVENT_PERCENTAGES = ['25', '50', '75', '100'] as const;

export type SCROLL_GA4_EVENT_PERCENTAGE_TYPE =
  (typeof SCROLL_GA4_EVENT_PERCENTAGES)[number];

// create a record with the percentage strings to be used in context to keep a record of the events that have been fired
export const SCROLL_GA4_EVENT_PERCENTAGE_STRING: {
  [K in SCROLL_GA4_EVENT_PERCENTAGE_TYPE]: `SCROLL_EVENT_${K}_PERCENT`;
} = {
  '25': 'SCROLL_EVENT_25_PERCENT',
  '50': 'SCROLL_EVENT_50_PERCENT',
  '75': 'SCROLL_EVENT_75_PERCENT',
  '100': 'SCROLL_EVENT_100_PERCENT',
};

export enum ConstantsSessionStorage {
  LIST_PRODUCT_KLARNA = 'list-product-klarna',
}
export const ONE_TRUST_CONSENT_UPDATED = 'OneTrustGroupsUpdated';
// ─────────────────────────────────────────────────────────────────────────────
export const VIDEO_GA4_EVENT_PERCENTAGES = [
  '0',
  '10',
  '25',
  '50',
  '75',
  '100',
] as const;

export type VIDEO_GA4_EVENT_PERCENTAGE_TYPE =
  (typeof VIDEO_GA4_EVENT_PERCENTAGES)[number];

// create a record with the percentage strings to be used in context to keep a record of the events that have been fired
export const VIDEO_GA4_EVENT_PERCENTAGE_STRING: {
  [K in VIDEO_GA4_EVENT_PERCENTAGE_TYPE]: `VIDEO_EVENT_${K}_PERCENT`;
} = {
  '0': 'VIDEO_EVENT_0_PERCENT',
  '10': 'VIDEO_EVENT_10_PERCENT',
  '25': 'VIDEO_EVENT_25_PERCENT',
  '50': 'VIDEO_EVENT_50_PERCENT',
  '75': 'VIDEO_EVENT_75_PERCENT',
  '100': 'VIDEO_EVENT_100_PERCENT',
};
// ─────────────────────────────────────────────────────────────────────────────
