import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/CountdownClock.ts

export type CountdownClockDocumentType = {
  id: string;
  _id: string;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  countdownEnd: string;
};

export const CountdownClockDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    availableFrom,
    availableTo,
    countdownEnd,
  }
`;
