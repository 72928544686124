import { Maybe } from 'utils/types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import { BackgroundOverlayObjectType } from 'groq/objects/BackgroundOverlayObject';
import { Puid } from 'groq/global-types';

import {
  CountdownClockDocument,
  CountdownClockDocumentType,
} from './CountdownClockDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/TrendingTile.ts

export type TrendingTileDocumentType = {
  id: string;
  _id: string;
  _type: string;
  backgroundImage?: Maybe<SanityImageObjectType>;
  backgroundOverlay?: Maybe<BackgroundOverlayObjectType>;
  backgroundType?: Maybe<string>;
  contentBelow?: Maybe<boolean>;
  copy?: Maybe<LocaleStringType>;
  countdownClock?: Maybe<CountdownClockDocumentType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  einsteinRecommender?: Maybe<string>;
  einsteinRecommenderAltText?: Maybe<string>;
  header?: Maybe<LocaleStringType>;
  overlay?: Maybe<boolean>;
  puid?: Maybe<Puid>;
  textColor?: Maybe<string>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
};

export const TrendingTileDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    "__typename": _type,
    puid,
    availableFrom,
    availableTo,
    ${LocaleString('header')},
    linkType,
    ${CallToActionObject('link')},
    ${LocaleString('copy')},
    textColor,
    overlay,
    backgroundType,
    einsteinRecommender,
    einsteinRecommenderAltText,
    ${SanityImageObject('backgroundImage')},
    ${CallToActionObject('ctas[]')},
    ${CountdownClockDocument('countdownClock->')},
    
    backgroundOverlay { ... },
  }
`;
