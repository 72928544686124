import { Maybe } from 'utils/types';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import { BannerIdentifier } from '__generated__/graphql';

import {
  CountdownClockDocument,
  CountdownClockDocumentType,
} from './CountdownClockDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/BodyContent.ts

export type BodyContentDocumentType = {
  _id: string;
  _type?: Maybe<string>;
  horizontalAlignment?: Maybe<string>;
  header?: Maybe<LocaleStringType>;
  copy?: Maybe<LocaleStringType>;
  logo?: Maybe<SanityImageObjectType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  countdownClock?: Maybe<CountdownClockDocumentType>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  puid?: Maybe<BannerIdentifier>;
};

export const BodyContentDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    horizontalAlignment,
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${SanityImageObject('logo')},
    ${CallToActionObject('ctas[]')},
    ${CallToActionObject('link')},
    ${CountdownClockDocument('countdownClock->')},
    linkType,
    availableFrom,
    availableTo,
    puid
  }
`;
