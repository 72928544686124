import { tw } from 'twind';
import { Icon } from '@cyber-cats/uds/elements';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';

import { linkClassNames } from './shared';

export const PumaAppLink = ({ onClick }: { onClick?: () => void }) => {
  const { pumaAppDownloadUrl } = useSiteConfig();
  const t = useTranslate();

  if (!pumaAppDownloadUrl) return null;

  return (
    <a
      rel="noopener noreferrer"
      href={pumaAppDownloadUrl}
      target="_blank"
      data-test-id="puma-app-footer-link"
      className={tw(linkClassNames)}
      onClick={onClick}
      title={t('pumaAppTitle')}
    >
      <Icon name="puma-app" size="7xl" invert color="base" className="p-3" />
    </a>
  );
};
