import { useEffect } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { SearchProductList } from 'ui/components/Search/SearchProductList';
import {
  SearchProductResult,
  SearchViewComponent,
} from 'ui/components/Search/types';
import { AnalyticsEvents, event, searchAnalyticsEvent } from 'utils/analytics';
import { extractProductVariants } from 'utils/extractProductVariants';
import { useSearchRecommendedProductsQuery } from '__generated__/graphql';

const RecommendedProducts: SearchViewComponent = ({
  setIsLoading,
  setSessionStorageData,
}) => {
  const t = useTranslate();

  const [searchFlyoutRecommendedProductsResult] =
    useSearchRecommendedProductsQuery();

  useEffect(() => {
    setIsLoading(searchFlyoutRecommendedProductsResult.fetching);
  }, [searchFlyoutRecommendedProductsResult.fetching, setIsLoading]);

  const trackProductClick = (product: SearchProductResult) => {
    if (setSessionStorageData) {
      setSessionStorageData(t('recommendedProducts'), product.name);
      searchAnalyticsEvent('0');
    }

    event(AnalyticsEvents.SEARCH_RECOMMENDED_PRODUCT_CLICK, {
      suggested_product: product.name,
    });
  };

  const searchFlyoutRecommendedProducts =
    searchFlyoutRecommendedProductsResult.data?.searchFlyoutRecommendedProducts;

  if (!searchFlyoutRecommendedProducts) return null;

  const variants = extractProductVariants(searchFlyoutRecommendedProducts);
  const products = variants.map(x => ({
    masterId: x.masterId,
    colorValue: x.colorValue,
    name: x.name,
    price: x.productPrice?.price ?? 0,
    salePrice: x?.productPrice?.salePrice ?? 0,
    image: x.images[0],
  }));

  return (
    <SearchProductList
      heading={t('recommendedProducts')}
      products={products}
      onClick={trackProductClick}
    />
  );
};

export default RecommendedProducts;
