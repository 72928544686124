import { withUrqlClient } from 'next-urql';
import router from 'next/router';
import { parseCookies } from 'nookies';
import { Exchange, fetchExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools';

import { isServer } from 'utils/constants';
import { getLocaleFromRequest, getLocaleFromUrl } from 'utils/locale';

import { cacheExchange } from './exchanges/cache';
import { errorExchange } from './exchanges/error';
import { createRetryExchange } from './exchanges/retry';
import { filterNoLocale } from './exchanges/filterNoLocale';
import {
  createClientAuthExchange,
  createSSRAuthExchange,
  getStoredAgentAuthData,
} from './exchanges/auth';

const getGraphQlUrl = () => {
  if (!isServer) {
    return window.location.origin + '/api/graphql';
  }

  const gqlEndpoint = process.env.GQL_ENDPOINT ?? '';

  if (!gqlEndpoint) {
    throw new Error(
      'GQL Endpoint must be provided in order for the web application to run!'
    );
  }

  return gqlEndpoint;
};

export const withUrql = withUrqlClient(
  (ssr, ctx) => ({
    fetchOptions: () => {
      const cookies = parseCookies(ctx);

      const locale = isServer
        ? getLocaleFromRequest(ctx!.req!)
        : getLocaleFromUrl(router.query);

      const options = {
        headers: {
          locale,
          accept:
            'application/graphql-response+json, application/graphql+json, application/json',
        },
      };

      const noCache = isServer
        ? ctx?.query?.draft
        : window.location.search.match('draft=');

      // This header ensures that GQL requests don't get cached
      if (noCache) options.headers['preview'] = true;

      const bloomReachId = cookies['_br_uid_2'];
      if (bloomReachId) options.headers['bloomreach-id'] = bloomReachId;

      const impersonation = getStoredAgentAuthData(ctx);
      if (impersonation) {
        options.headers['impersonation-agent'] = impersonation.identifier;
      }

      return options;
    },
    url: getGraphQlUrl(),
    preferGetMethod: false,
    exchanges: [
      process.env.NODE_ENV === 'development' && devtoolsExchange,
      cacheExchange,
      ssr,
      isServer ? createSSRAuthExchange(ctx!) : createClientAuthExchange(),
      createRetryExchange(),
      filterNoLocale,
      errorExchange,
      fetchExchange,
    ].filter(Boolean) as Exchange[],
  }),
  { ssr: true }
);
