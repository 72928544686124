import { SlotsGroqQuery } from 'groq/ContentSlots';
import {
  ProductCarouselDocument,
  ProductRecommenderDocument,
} from 'groq/documents';
import { ProductCarouselDocumentType } from 'groq/documents/ProductCarouselDocument';
import { ProductRecommenderDocumentType } from 'groq/documents/ProductRecommenderDocument';
import { LocalePortableText } from 'groq/shared/LocalePortableText';
import { ComponentWithFilters } from '__generated__/graphql';

export type ProductDetailsPageTemplateContent = {
  _id: string;
  _type: string;
  aboveProductStory: ComponentWithFilters[];
  belowProductStory: ComponentWithFilters[];
  belowProductGoogleShopping: ComponentWithFilters[];
  soldOutRecommender: (
    | ProductRecommenderDocumentType
    | ProductCarouselDocumentType
  )[];
  pdpCallout: any;
  stickyAddToCart: string;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    ProductDetailsPageTemplate: ProductDetailsPageTemplateContent;
  }
}

export const ProductDetailsPageTemplateQuery = `
  *[_id == lower($country) + "-ProductDetailsPageTemplate"][0] {
    _id,
    _type,
    aboveProductStory[] ${SlotsGroqQuery()},
    belowProductStory[] ${SlotsGroqQuery()},
    belowProductGoogleShopping[] ${SlotsGroqQuery()},
    soldOutRecommender[] -> {
      ${ProductRecommenderDocument('_type == "ProductRecommender" =>')},
      ${ProductCarouselDocument('_type == "ProductCarousel" =>')},
    },
    ${LocalePortableText('pdpCallout')},
    stickyAddToCart,
  }
`;
