import { useState } from 'react';
import { useIsomorphicLayoutEffect } from '@cyber-cats/uds/hooks';

import {
  getFocusTargets,
  getNextFocusTarget,
  getFirstFocusTarget,
  isRelatedNode,
} from './focus';

export interface ListboxFocusOptions {
  /** Ties the behaviour of the listbox to a combobox input. */
  ownerRef?: React.RefObject<HTMLElement | null | undefined>;
  /** Activates or deactivates the listbox focus behaviour. */
  isActive?: boolean;
  /** Allows focus to enter the listbox as usual when set to true. */
  allowFocus?: boolean;
  /** Automatically acquires focus on listbox when set to true. */
  autoFocus?: boolean;
  /** returns focus to it's owner when listbox is deactivated. */
  returnFocus?: boolean;
}

/** Activates listbox navigation on the element targeted with the `React.RefObject`. This will allow WAI-ARIA-compliant navigation with arrow keys in a secondary list. By default this list isn't auto focused or tabbable unlike a modal (Compared to `useFocusTrap`) */
export function useListboxFocus<T extends HTMLElement>(
  ref: React.RefObject<T | null>,
  options: ListboxFocusOptions = {}
) {
  const ownerRef = options.ownerRef;
  const isActive = options.isActive !== false;
  const allowFocus = !!options.allowFocus;
  const returnFocus = !!options.returnFocus;
  const autoFocus = !!options.autoFocus;
  const [active, setActive] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const { current: container } = ref;
    if (!container) {
      return;
    } else if (!allowFocus) {
      container.setAttribute('tabindex', '0');
      if (!container.hasAttribute('aria-modal')) {
        container.setAttribute('aria-modal', 'false');
      }
    }

    if (!isActive) {
      container.setAttribute('aria-hidden', 'true');
      if (active && returnFocus) {
        ownerRef?.current?.focus();
      }
      setActive(false);
    } else {
      container.setAttribute('aria-hidden', 'false');
      setActive(true);
    }
  }, [ref.current!, active, setActive, allowFocus, isActive]);

  useIsomorphicLayoutEffect(() => {
    if (!isActive) return;

    // Focus the first focusable element in the container
    if (autoFocus && ref.current) {
      const focusTarget = getFirstFocusTarget(ref.current);
      if (focusTarget) focusTarget.focus();
    }

    let willEnterFocus = false;
    let isFocusingForward = true;

    const onClick = event => {
      if (
        ref.current &&
        getFocusTargets(ref.current).indexOf(event.target) > -1
      ) {
        willEnterFocus = true;
      }
    };

    const onFocusIn = (event: FocusEvent) => {
      const { current: container } = ref;

      if (
        /* Focus shouldn't be prevented when it was explicitly moved */
        !container ||
        willEnterFocus ||
        allowFocus ||
        (ownerRef && event.target === ownerRef.current)
      ) {
        willEnterFocus = false;
        return;
      }

      // Prevent focus from automatically entering the listbox
      if (
        isRelatedNode(container, event.target as HTMLElement) &&
        !isRelatedNode(container, event.relatedTarget as HTMLElement)
      ) {
        const parent = (ownerRef && ownerRef.current) || container;
        const previousTarget = getNextFocusTarget(parent, true);
        const nextTarget = getNextFocusTarget(parent, false);
        const target =
          (isFocusingForward && nextTarget) ||
          (previousTarget !== event.relatedTarget && previousTarget) ||
          (nextTarget !== event.relatedTarget && nextTarget) ||
          null;
        if (target) {
          event.preventDefault();
          target.focus();
        }
      }
    };

    const onKeydown = (event: KeyboardEvent) => {
      const { current: container } = ref;
      if (event.defaultPrevented || !container) {
        return;
      }

      const activeElement = document.activeElement as HTMLElement;
      const isInputActive = activeElement && activeElement.tagName === 'INPUT';
      const focusTargets = getFocusTargets(container);

      if (event.code === 'Tab' && !allowFocus) {
        isFocusingForward = !event.shiftKey;
        if (container.contains(activeElement)) {
          const parent = (ownerRef && ownerRef.current) || container;
          const target = getNextFocusTarget(parent, event.shiftKey);
          if (target) {
            event.preventDefault();
            target.focus();
          }
        }
      } else if (!focusTargets.length) {
        return;
      } else if (
        !autoFocus &&
        (!ownerRef || !isRelatedNode(activeElement, ownerRef.current)) &&
        !isRelatedNode(activeElement, container)
      ) {
        return;
      } else if (
        (!isInputActive && event.code === 'ArrowRight') ||
        event.code === 'ArrowDown'
      ) {
        event.preventDefault();
        const focusedIndex = focusTargets.indexOf(activeElement);
        const nextFocusIndex =
          focusedIndex < focusTargets.length - 1 && focusedIndex >= 0
            ? focusedIndex + 1
            : 0;
        willEnterFocus = true;
        focusTargets[nextFocusIndex].focus();
      } else if (
        (!isInputActive && event.code === 'ArrowLeft') ||
        event.code === 'ArrowUp'
      ) {
        event.preventDefault();
        const focusedIndex = focusTargets.indexOf(activeElement);
        const nextFocusIndex =
          focusedIndex > 0 ? focusedIndex - 1 : focusTargets.length - 1;
        willEnterFocus = true;
        focusTargets[nextFocusIndex].focus();
      } else if (
        ownerRef &&
        ownerRef.current &&
        ownerRef.current.tagName === 'INPUT' &&
        activeElement !== ownerRef.current &&
        /^(Key|Digit)/.test(event.code)
      ) {
        ownerRef.current.focus();
      }
    };

    if (ref.current) {
      ref.current.addEventListener('mousedown', onClick, true);
    }
    document.body.addEventListener('focusin', onFocusIn);
    document.addEventListener('keydown', onKeydown);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('mousedown', onClick);
      }
      document.body.removeEventListener('focusin', onFocusIn);
      document.removeEventListener('keydown', onKeydown);
    };
  }, [ref, isActive, allowFocus, autoFocus]);
}
