import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { css, tw } from 'twind/css';

import { CallToActions } from 'ui/content/CallToActions';
import { Link } from 'ui/elements/Link';

export const BlockContentLink = (props, sCustomStyles, onClick?) => {
  const className = tw(
    'border-b-2 border-puma-black-500 hover:border-puma-gold-light',
    sCustomStyles
  );
  if (props.mark._type === 'mailLink')
    return (
      <a
        className={className}
        href={props.mark.link || props.mark.href}
        rel="noreferrer"
        target="_blank"
        onClick={onClick}
      >
        {props.children}
      </a>
    );

  return (
    <>
      {props.mark.link || props.mark.href ? (
        <Link
          href={props.mark.link || props.mark.href}
          className={className}
          newtab={props.mark.newtab}
          onClick={onClick}
        >
          {props.children}
        </Link>
      ) : null}
    </>
  );
};

/** Add an `id` attribute to an element so it can be a "link target" (e.g. "#element-id"). */
export const BlockContentLinkTarget = ({ _type, children, mark }) => {
  const Tag = _type;

  return (
    <>
      <a className="invisible -top-24 relative inline-block" id={mark.id}></a>
      <Tag>{children}</Tag>
    </>
  );
};

export const serializeBlockContent = {
  types: {
    cta: function Cta(props) {
      return (
        <div className={'mt-2'}>
          <CallToActions ctas={[props.node]} className={'inline-flex'} />
        </div>
      );
    },
    block: props => {
      if (/^h\d/.test(props.node.style)) {
        return React.createElement(
          props.node.style,
          {
            className: 'font-bold',
            id: props.node._key,
          },
          props.children
        );
      }

      return BlockContent.defaultSerializers.types.block(props);
    },
  },
  marks: {
    absoluteLink: BlockContentLink,
    internalLink: BlockContentLink,
    linkTarget: BlockContentLinkTarget,
    mailLink: BlockContentLink,
    sub: props => <sub>{props.children}</sub>,
    sup: props => <sup>{props.children}</sup>,
    highlight: props => (
      <span className="bg-puma-red text-white">{props.children}</span>
    ),
  },
};

export const serializeHeaderToList = {
  types: {
    block: props => {
      if (/^h\d/.test(props.node.style)) {
        return React.createElement(
          'li',
          {},
          React.createElement(
            'a',
            {
              href: `#${props.node._key}`,
              className: 'text-sm underline',
            },
            props.children
          )
        );
      }

      return BlockContent.defaultSerializers.types.block(props);
    },
  },
};

const TocBlockContentLink = props => BlockContentLink(props, 'text-xs');

export const serializeTocBlockContent = {
  ...serializeBlockContent,
  marks: {
    internalLink: TocBlockContentLink,
    absoluteLink: TocBlockContentLink,
  },
};

export const Prose = ({ content }) => (
  <BlockContent
    className={tw(
      'prose md:prose-m max-w-full',
      css`
        * {
          @apply break-words;
        }
        a {
          text-decoration: none;
        }
        ul {
          list-style-type: disc !important;
          list-style-position: inside !important;
        }
        ul ul {
          list-style-type: circle !important;
        }
        ul ul ul {
          list-style-type: square !important;
        }
        ol {
          list-style-type: decimal !important;
          list-style-position: inside !important;
        }
        ol ol {
          list-style-type: lower-alpha !important;
        }
        ol ol ol {
          list-style-type: lower-roman !important;
        }
      `
    )}
    renderContainerOnSingleChild
    blocks={content}
    serializers={serializeBlockContent}
  />
);
