import { gql } from 'urql';

import { AMBASSADOR_CARD_PAYMENT_FRAGMENT } from 'gql/fragments/ambassadorCards';

import { PRODUCT_ANALYTICS_DIMENSIONS_FRAGMENT } from './product';

export const CART_SHIPMENT_METHOD_FRAGMENT = gql`
  fragment shipmentMethodInfoFields on ShipmentMethodInfo {
    defaultShipmentMethodId
    availableShipmentMethods {
      id
      name
      price
      estimatedShippingDate {
        min
        max
      }
    }
  }
`;

export const CART_CUSTOMER_FRAGMENT = gql`
  fragment customerFields on Cart {
    customer {
      id
      email
      customerNo
    }
  }
`;

export const CART_SHIPMENT_FRAGMENT = gql`
  fragment shipmentFields on Shipment {
    id
    shipmentMethod {
      id
      name
      price
    }
    address {
      id
      firstName
      lastName
      address1
      address2
      postalCode
      city
      countryCode
      stateCode
      phone
      firstNamePronunciation
      lastNamePronunciation
      streetNumber
      salutation
      companyName
    }
    deliveryDate
    deliveryTimeCode
    deliveryTimeDisplayText
    authorityToLeave {
      leaveAt
      deliveryInstructions
    }
  }
`;

export const CART_BILLING_ADDRESS_FRAGMENT = gql`
  fragment billingAddressFields on Cart {
    billingAddress {
      firstName
      lastName
      address1
      address2
      postalCode
      city
      countryCode
      stateCode
      phone
      id
      firstNamePronunciation
      lastNamePronunciation
      streetNumber
      salutation
      companyName
    }
  }
`;

export const CART_COSTS_FRAGMENT = gql`
  fragment cartCostsFields on Cart {
    subTotal
    shippingCosts
    estimatedShippingCosts
    taxTotal
    taxation
    estimatedTotal
    amountRemaining
    orderAdjustments {
      price
    }
    customizationAdjustment {
      price
    }
  }
`;

export const CART_PROMO_FRAGMENT = gql`
  fragment cartPromoFields on Cart {
    couponItems {
      code
      couponItemId
      statusCode
      valid
      discountAmount
      calloutMessage
    }
    orderAdjustments {
      promotionId
      itemText
      price
      couponCode
    }
    shippingAdjustments {
      promotionId
      itemText
      price
      couponCode
    }
    productAdjustments {
      promotionId
      itemText
      price
      couponCode
    }
  }
`;

export const CART_PAYMENT_INSTRUMENTS = gql`
  fragment cartPaymentInstrumentsFields on PaymentInstrument {
    ... on AfterPay {
      __typename
      id
      type
    }
    ... on AmazonPay {
      __typename
      id
      type
    }
    ... on ApplePay {
      __typename
      id
      authorizationStatus
      type
    }
    ... on CashOnDelivery {
      __typename
      id
      type
    }
    ... on CreditCard {
      __typename
      id
      maskedNumber
      expirationMonth
      expirationYear
      holder
      type
    }
    ... on GiftCard {
      __typename
      id
      maskedNumber
      amount
      type
    }
    ... on OnlineBanking {
      __typename
      id
      type
    }
    ... on PayPal {
      __typename
      id
      type
    }
    ... on PayPay {
      __typename
      id
      type
    }
    ... on PayTm {
      __typename
      id
      type
    }
    ... on Razorpay {
      __typename
      id
      type
    }
    ... on Scheme {
      __typename
      id
      type
    }
    ... on StoreCreditInstrument {
      __typename
      id
      type
      amount
    }
    ... on UPI {
      __typename
      id
      type
    }
    ... on Wallet {
      __typename
      id
      type
    }
    ...ambassadorCardPaymentFields
  }

  ${AMBASSADOR_CARD_PAYMENT_FRAGMENT}
`;

export const CART_GIFT_WITH_PURCHASE_ITEMS_FRAGMENT = gql`
  fragment giftWithPurchaseFields on GiftWithPurchasePromo {
    id
    allowedQuantity
    promotionId
    calloutMessage
    details
    fullfilled
    availableProducts {
      ... on VariantProduct {
        __typename
        masterId
        id
        name
        price
        images {
          href
          alt
        }
        inventory
        primaryCategoryId
        styleNumber
        colorValue
        colorName
        size {
          label
          value
        }
      }
      ... on ProductWithExclusion {
        __typename
        excludedColors
        product {
          id
          name
          variations {
            id
            colorValue
            colorName
            salePrice
            price
            productPrice {
              price
              salePrice
              promotionPrice
            }
            preview
            displayOutOfStock {
              displayValue
              soldout
            }
            orderable
            sizeGroups {
              label
              description
              sizes {
                id
                label
                value
                productId
                orderable
                maxOrderableQuantity
              }
            }
            images {
              href
              alt
            }
          }
        }
      }
    }
  }
`;

export const CART_PRODUCT_ITEM_FRAGMENT = gql`
  fragment productCartItemFields on CartItem {
    ... on GiftCardCartItem {
      id
      imageUrl
      amount
      recipientEmail
      recipientName
      senderName
      __typename
    }
    ... on ProductCartItem {
      __typename
      id
      inCartPrice
      bonusItemId
      restricted
      quantity
      purchasedPrice {
        price
        promotionPrice
      }
      adjustments {
        promotionId
        itemText
        price
        couponCode
        promotion {
          id
          calloutMessage
          name
        }
        __typename
      }
      product {
        masterId
        id
        isAppExclusive
        promotionExclusion
        name
        price
        brand
        salePrice
        productPrice {
          price
          salePrice
          promotionPrice
        }
        images {
          href
          alt
        }
        inventory
        maxOrderableQuantity
        primaryCategoryId
        styleNumber
        ean
        upc
        colorValue
        colorName
        displayOutOfStock {
          soldout
          backsoon
          presale
          validTo
        }
        size {
          label
          value
        }
        offline
        orderable
        assortmentAvailability
      }
      customizationName
      customizationNumber
      customizationCost
      quantityInCart
      stockMessage
      stockMessageCode
      hasStock
      adjustQuantityMessage
      personalizationSurcharge
      personalizationImageURL
      personalizationLargeImageURL
      personalizationName
      personalizationNumber
      personalizationPrice
      personalizationRecipeID
      popularityCount
      estimatedDeliveryDate {
        id
        carrierCode
        eddBegin
        eddEnd
        errorResponse {
          code
          message
        }
        serviceCode
        timeZoneName
      }
    }
  }
`;

export const PRODUCT_CART_ITEM_ANALYTICS_DIMENSIONS_FIELDS = gql`
  fragment productCartItemAnalyticsDimensionsFields on CartItem {
    ... on ProductCartItem {
      product {
        ...productAnalyticsDimensionsFields
      }
      taxRate
    }
  }
  ${PRODUCT_ANALYTICS_DIMENSIONS_FRAGMENT}
`;

export const CART_AVAILABLE_PAYMENT_METHODS_FRAGMENT = gql`
  fragment availablePaymentMethodsFields on PaymentMethod {
    id
    cards {
      id
      cardType
      name
    }
    bank {
      id
      name
    }
    wallet {
      id
      name
    }
    description
    name
    paymentProcessorId
    c_additionalFee
  }
`;

export const CART_FRAGMENT = gql`
  fragment cartFields on Cart {
    id
    productCount
    amountRemainingForFreeShipping
    basketInStockStatus
    hasFreeShipping
    hasOfflineProducts
    preOrderDeliveryDate
    shippingSurcharge {
      amount
      label
      tooltip
    }
    products {
      ...productCartItemFields
    }
    giftWithPurchases {
      ...giftWithPurchaseFields
    }
    paymentInstruments {
      ...cartPaymentInstrumentsFields
    }
    ...customerFields
    ...cartCostsFields
    ...cartPromoFields
    ...billingAddressFields
    shipment {
      ...shipmentFields
    }
    shipmentMethodInfo {
      ...shipmentMethodInfoFields
    }
    longTermPreOrder {
      hasLongTermPreOrderItem
      longTermPreOrderItemPaymentMethods
    }
  }

  ${CART_CUSTOMER_FRAGMENT}
  ${CART_BILLING_ADDRESS_FRAGMENT}
  ${CART_COSTS_FRAGMENT}
  ${CART_PRODUCT_ITEM_FRAGMENT}
  ${CART_PROMO_FRAGMENT}
  ${CART_PAYMENT_INSTRUMENTS}
  ${CART_GIFT_WITH_PURCHASE_ITEMS_FRAGMENT}
  ${CART_SHIPMENT_FRAGMENT}
  ${CART_SHIPMENT_METHOD_FRAGMENT}
`;
