import {
  CollapsibleRoot,
  CollapsibleTrigger,
  CollapsibleContent,
  CollapsibleChevron,
  Container,
  Heading,
  Button,
  LinkIcon,
  LinkText,
  Stack,
} from '@cyber-cats/uds/elements';
import { IconNames } from '@cyber-cats/uds/icons';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  useCallback,
  useState,
  ReactNode,
  FunctionComponent as FC,
  useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { tw } from 'twind';
import { css } from 'twind/css';

import {
  NewsletterSignupResult,
  useNewsletterSignup,
} from 'hooks/useNewsletterSignup';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import {
  emailSignUpEvent,
  footerNavigationClickEvent,
  footerNavigationEvent,
} from 'utils/analytics';
import { EmailSignUpActions } from 'types/analyticTypes';
import {
  Cta,
  SignUpForm,
  useMainFooterQuery,
  useTrackAppLinkClickMutation,
} from '__generated__/graphql';
import { useLocation } from 'hooks/useLocation';
import ForterWebId from 'ui/elements/ForterWebId';
import { useStickyButtonIntersection } from 'hooks/useStickyButton';

import { AcceptedPaymentMethods } from './AcceptedPaymentMethods';
import { PumaAppLink, PumaTracLink } from './AppLinks';
import type { CookieSettingsProps } from './CookieConsentLayer/types';
import { FooterLinks } from './FooterLinks';
import { NewsletterSignUpButton } from './NewsletterSignUpButton';

const NewsletterSignup = dynamic(
  () =>
    import('ui/content/SignUpFormNewsletter').then(mod => mod.NewsletterSignup),
  { ssr: false }
);

const NewsletterSignupIFrame = dynamic(
  () => import('ui/content/SignUpFormIframe'),
  { ssr: false }
);

const CookieSettings = dynamic<CookieSettingsProps>(
  () => import('ui/components/CookieConsentLayer/CookieSettings'),
  { ssr: false }
);

export const MainFooter = () => {
  const router = useRouter();
  const t = useTranslate();
  const {
    country,
    countryCode,
    countryName,
    language,
    showPaymentPartnersInFooter,
    staticFeatures: { hideNewsletterSignupButtonOnMobile },
  } = useSiteConfig();
  const [selectedMobileFooterSection, setSelectedMobileFooterSection] =
    useState<string>();
  const [, trackAppLinkClick] = useTrackAppLinkClickMutation();
  const { location, setLocation } = useLocation();
  const stickyButtonRef = useRef(null);

  useStickyButtonIntersection(stickyButtonRef, { threshold: 1 });
  const onOpenFooterSection = useCallback(
    heading =>
      setSelectedMobileFooterSection(cur =>
        cur !== heading ? heading : undefined
      ),
    []
  );

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: { emailAddress: '' },
  });

  const [mainFooter] = useMainFooterQuery({
    variables: {
      isDraft: router.query.draft as string,
    },
  });

  const { legalLinks, supportLinks, aboutLinks, signUpForm, signUpForms } =
    mainFooter.data?.mainFooter ?? {};

  const singleSignUpForm = useNewsletterSignup(signUpForm);

  const newsLetterSignUpFormsMap = new Map<string, NewsletterSignupResult>(
    [
      [signUpForm?.id || 'emarsys-signup-form', singleSignUpForm],
      [signUpForms?.[0]?.id, useNewsletterSignup(signUpForms?.[0])],
      [signUpForms?.[1]?.id, useNewsletterSignup(signUpForms?.[1])],
    ].filter(([k]) => !!k) as [string, NewsletterSignupResult][]
  );

  const onShowNewsLetterSignup = (
    formId: string,
    ...args: Parameters<NewsletterSignupResult['show']>
  ) => {
    const form = newsLetterSignUpFormsMap.get(formId);
    if (form) form.show(...args);
  };

  const onHandleCloseNewsLetterSignup = (
    formId: string,
    ...args: Parameters<NewsletterSignupResult['handleClose']>
  ) => {
    const form = newsLetterSignUpFormsMap.get(formId);
    if (form) form.handleClose(...args);
  };

  // array of newsletter signup forms to show
  const emarsysEnabledNewsLetterSignupForms: SignUpForm[] = [];
  // signUpForm is deprecated
  // use signUpForm as fallback only if there are no signUpForms
  if (signUpForms?.length) {
    for (const form of signUpForms) {
      if (form) {
        const newsLetterSignUpForm = newsLetterSignUpFormsMap.get(form.id);
        if (newsLetterSignUpForm?.isEmarsysEnabled) {
          emarsysEnabledNewsLetterSignupForms.push(form as SignUpForm);
        }
      }
    }
  } else if (signUpForm && singleSignUpForm.isEmarsysEnabled) {
    emarsysEnabledNewsLetterSignupForms.push(signUpForm as SignUpForm);
  } else if (singleSignUpForm.isEmarsysEnabled) {
    emarsysEnabledNewsLetterSignupForms.push({
      id: 'emarsys-signup-form',
      ...singleSignUpForm,
    } as any);
  }

  const STICKY_BUTTON_LABEL_UK = 'NEW HERE? TAKE 15% OFF*';

  const [selectedForm, setSelectedForm] = useState<
    (SignUpForm & { emarsysSignupURL?: boolean }) | null
  >({
    ...emarsysEnabledNewsLetterSignupForms[0],
    header:
      emarsysEnabledNewsLetterSignupForms[0]?.header || STICKY_BUTTON_LABEL_UK,
  });

  const handleClickForm = (form: SignUpForm) => {
    setSelectedForm({
      ...form,
      header: form.header || STICKY_BUTTON_LABEL_UK,
    });
  };

  const isCart = router.asPath.includes('/cart');

  const displaySignUpButton =
    selectedForm?.header &&
    newsLetterSignUpFormsMap.get(selectedForm.id)?.isButtonVisible &&
    !isCart;

  const includeNewsletterComponents =
    !router.asPath.includes('/help/financial-incentives') &&
    selectedForm &&
    newsLetterSignUpFormsMap.get(selectedForm.id)?.isFormVisible;
  return (
    <>
      {displaySignUpButton && (
        <div
          className={`sticky overflow-hidden bottom-0 px-4 py-4 lg:px-8 lg:py-8 block z-[11] pointer-events-none ${
            hideNewsletterSignupButtonOnMobile ? 'mobile:hidden' : ''
          }`}
        >
          <NewsletterSignUpButton
            label={selectedForm.launchButtonLabel || selectedForm.header!}
            location={'StickySignUpButton'}
            onClick={() =>
              onShowNewsLetterSignup(selectedForm.id, 'SiteFooter')
            }
            onDismiss={() => {
              onHandleCloseNewsLetterSignup(selectedForm.id, true);
              emailSignUpEvent(
                EmailSignUpActions.ButtonDismissed,
                'StickySignUpButton'
              );
            }}
          />
        </div>
      )}
      <div ref={stickyButtonRef} />
      <footer
        role="contentinfo"
        className="font-display relative bg-puma-black-100 text-puma-black-400"
        aria-label="Main Footer"
      >
        <Container size="page" asChild>
          <nav
            className="py-8 lg:py-10 text-neutral-20 space-y-6"
            aria-label="Footer Nav"
          >
            {/* === TOP PART OF FOOTER === */}
            <Container
              size="xl"
              data-test-id="footer-top"
              data-footer-position="top"
              className="flex flex-col lg:flex-row justify-between gap-2"
            >
              <div className="lg:flex-[2]">
                <FooterSection
                  dataTestId="footer-support-section"
                  heading={t('support')}
                  current={selectedMobileFooterSection}
                  onOpen={onOpenFooterSection}
                >
                  <ul role="menu" className="grid grid-cols-2 gap-x-6 gap-y-1">
                    <FooterLinks
                      links={supportLinks}
                      parentNavigationName="Support"
                    />
                    <CookieSettings />
                  </ul>
                </FooterSection>
              </div>

              <div className="lg:flex-[1]">
                <FooterSection
                  dataTestId="footer-about-section"
                  heading={t('about')}
                  current={selectedMobileFooterSection}
                  onOpen={onOpenFooterSection}
                >
                  <ul role="menu" className="grid grid-cols-1 gap-x-6 gap-y-1">
                    <FooterLinks
                      links={aboutLinks}
                      parentNavigationName="About"
                    />
                  </ul>
                </FooterSection>
              </div>

              <div className="lg:flex-[1] flex flex-col gap-2 lg:gap-6">
                <>
                  {(emarsysEnabledNewsLetterSignupForms.length ||
                    showPaymentPartnersInFooter) && (
                    <FooterSection
                      dataTestId="footer-stay-up-to-date-section"
                      heading={t('newsletterSignUp')}
                      current={selectedMobileFooterSection}
                      onOpen={onOpenFooterSection}
                    >
                      {emarsysEnabledNewsLetterSignupForms.length > 0 && (
                        <ul
                          role="menu"
                          className="grid grid-cols-1 gap-x-6 gap-y-1"
                        >
                          {emarsysEnabledNewsLetterSignupForms.map(form => (
                            <li key={form.id} role="none" className="flex">
                              <a
                                data-test-id={`signup-link-${form.id}`}
                                className="hover:text-white"
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  onShowNewsLetterSignup(form.id, 'SiteFooter');
                                  handleClickForm(form);
                                  footerNavigationEvent({
                                    parentNavigation: 'Email Signup',
                                  });
                                  footerNavigationClickEvent(
                                    'stay up to date: sign up for email'
                                  );
                                }}
                              >
                                {form.launchLinkText ||
                                  t('newsletterSignUpCTA')}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                      {showPaymentPartnersInFooter && (
                        <SocialMediaFooterLinks className="mt-6" />
                      )}
                    </FooterSection>
                  )}

                  <FooterSection
                    dataTestId="footer-explore-section"
                    heading={t('appSectionLabel')}
                    current={selectedMobileFooterSection}
                    onOpen={onOpenFooterSection}
                  >
                    <div className="flex flex-row mt-4 gap-9">
                      <PumaAppLink
                        onClick={() => {
                          footerNavigationEvent({
                            parentNavigation: 'PUMAApp',
                          });
                          footerNavigationClickEvent('explore:pumaapp');
                          trackAppLinkClick({
                            link: 'footerNavigationClickEvent',
                          });
                        }}
                      />
                      <PumaTracLink
                        onClick={() => {
                          footerNavigationEvent({
                            parentNavigation: 'PUMATrac',
                          });
                          footerNavigationClickEvent('explore:pumatrac');
                          trackAppLinkClick({ link: 'PUMATrac' });
                        }}
                      />
                    </div>
                  </FooterSection>
                </>
              </div>
            </Container>

            {/* === BOTTOM PART OF FOOTER === */}
            <div
              data-test-id="footer-bottom"
              data-footer-position="bottom"
              className={tw([
                'grid gap-x-3 gap-y-6',
                'grid-rows[auto_1px_auto_auto] grid-cols-1 items-start',
                'xs:grid-rows-[auto_1px_auto] xs:grid-cols-2',
                'lg:grid-rows-[1px_auto] lg:grid-cols-3 lg:items-center',
              ])}
            >
              {/* language selector */}
              <div
                className={tw([
                  'row-start-1',
                  'xs:row-start-1 xs:col-start-1 xs:col-span-2',
                  'lg:row-start-2 lg:col-start-2 lg:col-span-1 lg:justify-self-center',
                ])}
              >
                <Button
                  data-test-id="select-location"
                  label={countryName}
                  icon={`flag-${countryCode}`}
                  variant="secondary"
                  size={{ _: 'base', lg: 'lg' }}
                  invert
                  className="w-full lg:w-auto lg:mx-auto"
                  onClick={() => {
                    setLocation({ ...location, selecting: true });
                    footerNavigationEvent({ parentNavigation: 'Language' });
                    footerNavigationClickEvent('Language');
                  }}
                />
              </div>

              {/* horizontal line */}
              <hr
                className={tw('border-0 border-t-1 border-puma-black-400', [
                  'row-start-2',
                  'xs:row-start-2 xs:col-span-2',
                  'lg:row-start-1 lg:col-start-1 lg:col-span-3',
                ])}
              />

              {/* payment partners / social media links */}
              <div
                className={tw([
                  'row-start-3',
                  'xs:row-start-3',
                  'lg:row-start-2 lg:col-start-1 lg:col-span-1',
                ])}
              >
                {showPaymentPartnersInFooter ? (
                  <AcceptedPaymentMethods
                    dataTestId="accepted-payment-methods"
                    className="flex gap-2 flex-wrap"
                  />
                ) : (
                  <SocialMediaFooterLinks />
                )}
              </div>

              {/* copyright / legal links / web ID */}
              <div
                className={tw([
                  'row-start-4',
                  'xs:row-start-3',
                  'lg:row-start-2 lg:col-start-3 lg:col-span-1',
                ])}
              >
                <div className="flex flex-col text-xs text-right text-neutral-40 uppercase">
                  <CopyrightAndLegalLinks legalLinks={legalLinks} />
                  <ForterWebId />
                </div>
              </div>
            </div>
          </nav>
        </Container>
      </footer>
      {includeNewsletterComponents &&
        (selectedForm.emarsysSignupURL ? (
          <NewsletterSignupIFrame
            email={form.getValues()['emailAddress']}
            emarsysSignupURL={singleSignUpForm.emarsysSignupURL!}
            country={country}
            language={language}
            onClose={() =>
              onHandleCloseNewsLetterSignup(
                signUpForm?.id || 'emarsys-signup-form',
                true
              )
            }
            signUpLocation="SiteFooter"
          />
        ) : (
          selectedForm && (
            <NewsletterSignup
              {...selectedForm}
              onClose={() =>
                onHandleCloseNewsLetterSignup(selectedForm.id, false)
              }
              onSubmit={() =>
                onHandleCloseNewsLetterSignup(selectedForm.id, true)
              }
              signUpLocation={
                newsLetterSignUpFormsMap.get(selectedForm.id)?.signUpLocation
              }
            />
          )
        ))}
    </>
  );
};

type FooterSectionProps = {
  dataTestId: string;
  heading: string;
  onOpen: (heading: string) => void;
  current?: string;
  children?: ReactNode;
};

const FooterSection = ({
  dataTestId,
  heading,
  current,
  onOpen,
  children,
}: FooterSectionProps) => {
  const isOpen = current === heading;

  return (
    <>
      <Stack
        gap="xxs"
        data-footer-type="full"
        data-test-id={dataTestId}
        className={tw('mobile:hidden tablet:hidden desktop:flex')}
      >
        <Heading size="xs" transform="uppercase" color="base" invert asChild>
          <div>{heading}</div>
        </Heading>
        {children}
      </Stack>
      <CollapsibleRoot
        invert
        open={isOpen}
        onOpenChange={() => onOpen(heading)}
        dataTestId={dataTestId}
        data-footer-type="collapsible"
        className="desktop:hidden"
      >
        <div>
          <CollapsibleTrigger
            insetY="xxs"
            insetX="xxxs"
            className="flex flex-row justify-between items-center"
          >
            <Heading size="xs" transform="uppercase">
              {heading}
            </Heading>
            <CollapsibleChevron />
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent
          insetY="xxs"
          insetX="xxxs"
          className={tw(css`
            --radix-collapsible-content-height: auto !important;
          `)}
        >
          {children}
        </CollapsibleContent>
      </CollapsibleRoot>
    </>
  );
};

const CopyrightAndLegalLinks = ({
  legalLinks = [],
}: {
  legalLinks?: Cta[];
}) => {
  const t = useTranslate();
  const currentYear = new Date().getFullYear();

  const links: Cta[] = [...legalLinks];
  if (links.length === 0) {
    links.push({
      _type: 'Cta',
      id: 'imprint-legal-data-link',
      link: '/help/imprint',
      title: t('imprintAndLegalData'),
    });
  }

  return (
    <>
      <div data-test-id="copyright-notice">
        {t<'allRightsReserved'>('allRightsReserved', {
          year: currentYear,
        })}
      </div>
      <ul data-test-id="footer-legal-section" role="menu">
        {links.map(link => {
          const newtabProps = link.newtab
            ? { target: '_blank', rel: 'noopener noreferrer' }
            : {};
          return (
            <li key={link.link} role="none">
              <LinkText
                dataTestId={link.id ?? undefined}
                href={link.link}
                label={link.title ?? ''}
                className="underline-offset-0"
                onClick={() => {
                  if (link.title) {
                    footerNavigationEvent({
                      parentNavigation: `Legal:${link.title}`,
                      linkClicked: link.title,
                    });
                  }
                  footerNavigationClickEvent(`Legal:${link.title}`);
                }}
                {...newtabProps}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};

const SocialMediaFooterLinks = ({ className }: { className?: string }) => {
  const { socialMediaLinks } = useSiteConfig();
  const t = useTranslate();
  return (
    <div data-test-id="social-media-footer-links" className={className}>
      <ul
        className="flex justify-between xs:justify-start xs:gap-6"
        role="menu"
        aria-label={t('moreInspiration')}
      >
        <SocialMediaLink
          id="social-youtube"
          name="YouTube"
          url={socialMediaLinks?.youtube || 'https://www.youtube.com/puma'}
        />
        <SocialMediaLink
          id="social-twitter"
          name="Twitter"
          url={socialMediaLinks?.twitter || 'https://twitter.com/PUMA'}
        />
        <SocialMediaLink
          id="social-pinterest"
          name="Pinterest"
          url={socialMediaLinks?.pinterest || 'https://www.pinterest.com/puma/'}
        />
        <SocialMediaLink
          id="social-instagram"
          name="Instagram"
          url={socialMediaLinks?.instagram || 'https://instagram.com/puma/'}
        />
        <SocialMediaLink
          id="social-facebook"
          name="Facebook"
          url={socialMediaLinks?.facebook || 'https://www.facebook.com/PUMA/'}
        />
      </ul>
    </div>
  );
};

type SocialMediaLinkProps = {
  id: IconNames;
  name: string;
  url: string;
};

const SocialMediaLink: FC<SocialMediaLinkProps> = ({ id, name, url }) => (
  <li role="none">
    <LinkIcon
      role="menuitem"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      dataTestId={`${id}-social-link`}
      variant="primary"
      label={name}
      icon={id}
      onClick={() => {
        footerNavigationEvent({
          parentNavigation: `Social:${name}`,
          linkClicked: name,
        });
      }}
    ></LinkIcon>
  </li>
);
