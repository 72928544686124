import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
  Dispatch,
  MutableRefObject,
} from 'react';
import { useRouter } from 'next/router';

import { isServer } from 'utils/constants';
import { ProductDetailsPageTemplateQuery } from 'groq/pages/ProductDetailsPageTemplate';
import { getPageType } from 'utils/analytics';

import { useSiteConfig } from './useSiteConfig';
import { useCookieSettings } from './useCookieSettings';
import { useMainNavContext } from './useMainNav';
import { useGroqQuery } from './useGroqQuery';

type SetStateActionBoolean = Dispatch<React.SetStateAction<boolean>>;

export type StickyButtonContextType = {
  showStickyButton: boolean;
  showSizesModal: boolean;
  isIntersected: boolean;
  buttonRef: HTMLButtonElement | null;
  setButtonRef: Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  setShowStickyButton: SetStateActionBoolean;
  setShowSizesModal: SetStateActionBoolean;
  setIsIntersected: SetStateActionBoolean;
};

const StickyButtonContext = createContext<StickyButtonContextType>({
  showStickyButton: false,
  showSizesModal: false,
  isIntersected: false,
  buttonRef: null,
  /* eslint-disable @typescript-eslint/no-empty-function */
  setButtonRef: () => {},
  setShowStickyButton: () => {},
  setShowSizesModal: () => {},
  setIsIntersected: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
});

export const useStickyButtonIntersection = (
  ref: MutableRefObject<HTMLElement | null>,
  options?: IntersectionObserverInit
) => {
  const { setIsIntersected, setButtonRef } = useStickyButton();

  useEffect(() => {
    if (ref.current && !isServer) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          setIsIntersected(entry.isIntersecting);
          if (ref.current instanceof HTMLButtonElement) {
            setButtonRef(ref.current);
          }
        });
      }, options ?? { threshold: 0 });

      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export const StickyButtonProvider: React.FC = ({ children }) => {
  const { cookieConsent } = useSiteConfig();
  const [showStickyButton, setShowStickyButton] = useState(false);
  const [showSizesModal, setShowSizesModal] = useState(false);
  const [isIntersected, setIsIntersected] = useState(false);
  const [buttonRef, setButtonRef] = useState<null | HTMLButtonElement>(null);

  const router = useRouter();
  const { showCookieBanner, showCookieSettings, showingCookieBannerOT } =
    useCookieSettings();

  const { mobileMenuOpen } = useMainNavContext();
  const [pdpContentTemplateQuery] = useGroqQuery({
    operationName: 'ProductDetailsPageTemplate',
    query: ProductDetailsPageTemplateQuery,
  });
  const stickyBehavior =
    pdpContentTemplateQuery?.data?.content?.stickyAddToCart || 'always';

  const isProductPage = useMemo(
    () => getPageType(router.pathname) === 'pdp',
    [router.pathname]
  );

  const handleScroll = useCallback(() => {
    if (!buttonRef) return;
    const addToCartButtonRef =
      buttonRef.getBoundingClientRect().top + window.scrollY;
    const position = window.scrollY || document.documentElement.scrollTop;
    const shouldShowStickyButton =
      !isIntersected && position >= addToCartButtonRef;
    setShowStickyButton(shouldShowStickyButton);
  }, [buttonRef, isIntersected]);

  useEffect(() => {
    const shouldHandleScroll =
      (showingCookieBannerOT && cookieConsent?.provider === 'OneTrust') ||
      showCookieBanner ||
      showCookieSettings ||
      mobileMenuOpen ||
      !isProductPage ||
      stickyBehavior;
    if (shouldHandleScroll) {
      switch (stickyBehavior) {
        case 'always':
          setShowStickyButton(!isIntersected);
          break;
        case 'afterScroll':
          window.addEventListener('scroll', handleScroll);
          break;
        case 'disabled':
          setShowStickyButton(false);
          break;
      }
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    buttonRef,
    cookieConsent?.provider,
    handleScroll,
    isIntersected,
    isProductPage,
    mobileMenuOpen,
    showCookieBanner,
    showCookieSettings,
    showingCookieBannerOT,
    stickyBehavior,
  ]);

  const contextValue = useMemo(
    () => ({
      showStickyButton,
      showSizesModal,
      buttonRef,
      setButtonRef,
      setShowSizesModal,
      setShowStickyButton,
      setIsIntersected,
      isIntersected,
    }),
    [showStickyButton, showSizesModal, isIntersected, buttonRef]
  );

  return (
    <StickyButtonContext.Provider value={contextValue}>
      {children}
    </StickyButtonContext.Provider>
  );
};

export const useStickyButton = () => useContext(StickyButtonContext);
