import { Icon } from '@cyber-cats/uds/elements';
import React from 'react';
import { tw, animation, css } from 'twind/css';

export const LoadingSpinner: React.FC<{
  className?: string;
  dataTestId?: string;
}> = ({ className, dataTestId = 'loading-spinner' }) => {
  const pulse = animation('4s ease infinite .2s', {
    '0%, 100%': { opacity: '0%' },
    '40%': { opacity: '10%' },
  });
  return (
    <div
      className={tw('m-auto max-w-[40vw] opacity-0', css(pulse), className)}
      data-test-id={dataTestId}
    >
      <Icon name="puma-wordmark" />
    </div>
  );
};
