import { useState } from 'react';
import { Button, Link } from '@cyber-cats/uds/elements';

import { useFeature } from 'hooks/useFeature';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';

export const PhoneNavLink = (props: { darkMode: boolean }) => {
  const t = useTranslate();
  const showPhoneInCheckout = useFeature('CAN_SHOW_PHONEICON_IN_CHECKOUT');
  const { phoneAnchorNumberInCheckout, phoneTextNumberInCheckout } =
    useSiteConfig();
  const [showNumber, toggleShowNumber] = useState(false);

  if (
    !showPhoneInCheckout ||
    !phoneAnchorNumberInCheckout ||
    !phoneTextNumberInCheckout
  ) {
    return null;
  }

  return (
    <>
      <Link
        href={phoneAnchorNumberInCheckout}
        label={t('phone')}
        className="md:hidden"
        mode="icon"
        icon="phone"
        variant="ghost"
        invert={props.darkMode}
      />

      <Button
        className="hidden md:flex"
        dataTestId="main-nav-phone-button"
        onClick={() => toggleShowNumber(!showNumber)}
        label={showNumber ? phoneTextNumberInCheckout : t('phone')}
        icon={showNumber ? undefined : 'phone'}
        mode={showNumber ? 'text' : 'icon'}
        variant="ghost"
        invert={props.darkMode}
      />
    </>
  );
};
