import { useState, useEffect, useCallback } from 'react';
import type { MutableRefObject } from 'react';
import { useRouter } from 'next/router';

import { AnalyticsEvents, event } from 'utils/analytics';

import { useIntersectionObserver } from './useIntersectionObserver';
import { usePageEventsContext } from './usePageEventsContext';
import { usePreferences } from './usePreferences';

export type GaTrackData = {
  id: string;
  promotion_id?: string;
  name: string;
  creative: string;
  creative_name?: string;
  position?: string;
  campaign?: string;
  items?: any[];
};

let componentPosition = 0;
let componentPositionGa4 = 0;

export const usePromotionView = (
  ref: MutableRefObject<HTMLElement | null>,
  data: GaTrackData,
  ga4Only?: boolean,
  gaOnly?: boolean
) => {
  const router = useRouter();
  const { preferences } = usePreferences();
  const { pageviewEventHasFired } = usePageEventsContext();

  const [gaViewEventFired, setGaViewEventFired] = useState(false);
  const [ga4ViewEventFired, setGa4ViewEventFired] = useState(false);

  const [pos] = useState(() => {
    const thisPos = componentPosition;
    if (ga4Only) return thisPos;
    componentPosition += 1;
    return thisPos;
  });

  const [posGa4] = useState(() => {
    const thisPos = componentPositionGa4;
    if (gaOnly) return thisPos;
    componentPositionGa4 += 1;
    return thisPos;
  });

  const onRouteChangeDone = useCallback(() => {
    componentPosition = 0;
    componentPositionGa4 = 0;
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChangeDone);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeDone);
    };
  }, [onRouteChangeDone, router.events]);

  useIntersectionObserver(
    ref,
    () => {
      if (!ga4Only) {
        if (!gaViewEventFired && pageviewEventHasFired) {
          const promotionData = { ...data, position: pos.toString() };
          if (promotionData.items) delete promotionData.items;
          if (promotionData.creative_name) delete promotionData.creative_name;
          if (promotionData.promotion_id) delete promotionData.promotion_id;
          event(AnalyticsEvents.PROMOTION_VIEW, {
            ecommerce: {
              promoView: {
                promotions: [promotionData],
              },
            },
          });
          setGaViewEventFired(true);
        }
      }

      if (gaOnly || ga4ViewEventFired || !pageviewEventHasFired) return;

      let item_name_ep: string | undefined;
      let item_id_ep: string | undefined;
      const isPdpPage = router.asPath.includes('/pd/');

      if (isPdpPage) {
        const lastGa4Item = preferences['last-ga4-item'];
        if (lastGa4Item) {
          item_name_ep = lastGa4Item.name;
          item_id_ep = `${lastGa4Item.id}_${lastGa4Item.swatch}`;
        }
      }

      const creative_name =
        data.creative_name === ''
          ? undefined
          : data.creative_name ??
            (data.creative === '' ? undefined : data.creative);
      const creative_slot = posGa4.toString();
      const promotion_id =
        data.promotion_id === ''
          ? undefined
          : data.promotion_id ?? (data.id === '' ? undefined : data.id);
      const promotion_name = data.name === '' ? undefined : data.name;

      event(AnalyticsEvents.GA4EC_PromotionView, {
        event_name: AnalyticsEvents.VIEW_PROMOTION,
        ecommerce: {
          creative_name,
          creative_slot,
          promotion_id,
          promotion_name,
          item_name_ep,
          item_id_ep,
          items: [],
        },
      });

      setGa4ViewEventFired(true);
    },
    true,
    {
      threshold: 0.5,
    }
  );
  return {
    promotionTrackerPosition: pos.toString(),
    ga4PromotionTrackerPosition: posGa4.toString(),
  };
};
