import { Maybe } from 'utils/types';
import {
  CallToActionObjectType,
  CallToActionObject,
} from 'groq/objects/CallToActionObject';
import { LocaleString } from 'groq/shared/LocaleString';

import { CampaignDocument, CampaignDocumentType } from './CampaignDocument';
import {
  CountdownClockDocument,
  CountdownClockDocumentType,
} from './CountdownClockDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/GlobalPromoBanner.ts

export type Information = {
  title: string;
  details: JSON;
};

export type Message = {
  __typename?: 'Message';
  id: string;
  _key: string;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  campaign?: Maybe<Array<Maybe<CampaignDocumentType>>>;
  countdownClock?: Maybe<CountdownClockDocumentType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  information?: Maybe<Information>;
  text: string;
};

export type GlobalPromoBannerType = {
  id: string;
  _id: string;
  _type?: string;
  duration: Maybe<number>;
  messages: Maybe<Message[]>;
};

export const GlobalPromoBannerDocument = `
  *[_id == lower($country) + "-GlobalPromoBanner"][0] {
      "id": _id,
      _id,
      _type,
      duration,
      messages[] {
        "id": _key,
        _key,
        "_type": "Message",
        ${LocaleString('text')},
        ${CallToActionObject('ctas[]')},
        ${CampaignDocument('campaign[]->')},
        information {
          ${LocaleString('title')},
          details,
        },
        ${CountdownClockDocument('countdownClock->')},
        availableFrom,
        availableTo,
      }
    }
  `;
