import { tw } from 'twind/css';

import { isServer } from 'utils/constants';
import {
  BackgroundOverlay,
  Maybe,
  SanityImage as SanityImageType,
} from '__generated__/graphql';
import { getImageMediaCondition } from 'utils/image';
import { ImageOverlay } from 'ui/elements/ImageOverlay';
import { useGA4Events } from 'hooks/useGA4Events';

export type SanityImageTypeProps = {
  className?: string;
  inline?: boolean;
  priority?: boolean;
  source?: Maybe<SanityImageType>;
  mobileSource?: Maybe<SanityImageType>;
  overlay?: Maybe<BackgroundOverlay>;
};

const placeholderImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const SanityImage: React.FC<
  SanityImageType & SanityImageTypeProps
> = props => {
  const { fireClickProductImage } = useGA4Events();
  const asset = props.source?.asset;
  const mobileAsset = props.mobileSource?.asset;
  const hasUrl = asset?.url || mobileAsset?.url;
  if (!hasUrl) return null;

  const blur = asset?.metadata.lqip || mobileAsset?.metadata.lqip;
  const alt = props.source?.alt || props.mobileSource?.alt || '';

  const mobileMediaCondition = getImageMediaCondition('mobile');
  const tabletMediaCondition = getImageMediaCondition('tablet');
  const desktopMediaCondition = getImageMediaCondition('desktop');

  const shouldRender = props.priority || !isServer;
  const imageFormat = 'q=80&auto=format';

  return (
    <picture
      data-test-id="sanity-image"
      className={tw(
        'flex w-full bg-puma-black-800 pointer-events-none',
        props.inline ? 'relative' : 'absolute inset-0 h-full',
        props.className
      )}
      style={
        shouldRender
          ? {
              backgroundImage: `url(${blur})`,
              backgroundSize: 'cover',
            }
          : undefined
      }
    >
      {shouldRender && (
        <>
          <source
            srcSet={`${asset?.url}?w=2160&${imageFormat}`}
            media={desktopMediaCondition}
            onClick={() =>
              fireClickProductImage(`${asset?.url}?w=2160&${imageFormat}`)
            }
          />
          <source
            srcSet={`${asset?.url}?w=1440&${imageFormat}`}
            media={tabletMediaCondition}
            onClick={() =>
              fireClickProductImage(`${asset?.url}?w=1440&${imageFormat}`)
            }
          />
          <source
            srcSet={`${mobileAsset?.url || asset?.url}?w=720&${imageFormat}`}
            media={mobileMediaCondition}
            onClick={() =>
              fireClickProductImage(
                `${mobileAsset?.url || asset?.url}?w=720&${imageFormat}`
              )
            }
          />
        </>
      )}
      <img
        srcSet={placeholderImage}
        className="object-cover w-full h-full"
        alt={alt}
        loading="eager"
        decoding="auto"
      />
      {props.source?.overlay && (
        <ImageOverlay
          className="invisible tablet:visible desktop:visible"
          {...props.source.overlay}
        />
      )}
      {props.mobileSource?.overlay && (
        <ImageOverlay
          className="invisible mobile:visible"
          {...props.mobileSource?.overlay}
        />
      )}
    </picture>
  );
};
