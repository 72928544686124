import { useEffect } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { SearchProductList } from 'ui/components/Search/SearchProductList';
import {
  SearchProductResult,
  SearchViewComponent,
} from 'ui/components/Search/types';
import { AnalyticsEvents, event, searchAnalyticsEvent } from 'utils/analytics';
import { useVariantsQuery } from '__generated__/graphql';

const RecentlyViewedProducts: SearchViewComponent = ({
  recentlyViewedProducts,
  setIsLoading,
  setSessionStorageData,
}) => {
  const t = useTranslate();

  const [variantsResult] = useVariantsQuery({
    variables: { input: { styleNumberIds: recentlyViewedProducts } },
  });

  useEffect(() => {
    setIsLoading(variantsResult.fetching);
  }, [variantsResult.fetching, setIsLoading]);

  const trackProductClick = (product: SearchProductResult) => {
    if (setSessionStorageData) {
      setSessionStorageData(t('recentlyViewed'), product.name);
      searchAnalyticsEvent('0');
    }

    event(AnalyticsEvents.SEARCH_RECENTLY_VIEWED_PRODUCT_CLICK, {
      recently_viewed_product: product.name,
    });
  };

  const variants = variantsResult.data?.variants;
  if (!variants) return null;

  const products = variants.map(product => {
    return {
      masterId: product.masterId,
      colorValue: product.colorValue,
      name: product.name,
      price: product.productPrice?.price ?? 0,
      salePrice: product.productPrice?.salePrice ?? 0,
      image: product.images[0],
    };
  });

  return (
    <SearchProductList
      heading={t('recentlyViewed')}
      products={products}
      onClick={trackProductClick}
    />
  );
};

export default RecentlyViewedProducts;
