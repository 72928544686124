import { Maybe } from 'utils/types';
import {
  ImageAssetDocument,
  ImageAssetDocumentType,
} from 'groq/documents/ImageAssetDocument';
import { LocaleString } from 'groq/shared/LocaleString';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/figure.ts

export type SanityImageObjectType = {
  alt?: Maybe<string>;
  asset?: Maybe<ImageAssetDocumentType>;
};

export const SanityImageObject = field => `
  ${field} {
    ${LocaleString('alt')},
    ${ImageAssetDocument('asset->')},
    overlay
  }
`;
