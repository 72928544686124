import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/SignUpForm.ts

export type SignUpFormDocumentType = {
  _id: string;
  _type: string;
  analyticsIdentifier?: Maybe<string>;
  launchButtonLabel?: Maybe<LocaleStringType>;
  launchLinkText?: Maybe<LocaleStringType>;
  cartPageLaunchButtonLabel?: Maybe<LocaleStringType>;
  header: LocaleStringType;
  copy?: Maybe<LocaleStringType>;
  footnote?: Maybe<LocaleStringType>;
  contactSource: string;
  disclaimerText?: Maybe<LocaleStringType>;
  submitButtonText: LocaleStringType;
  customText?: Maybe<LocaleStringType>;
  fields: Array<string>;
  successHeader?: Maybe<LocaleStringType>;
  successCopy?: Maybe<LocaleStringType>;
  successFootnote?: Maybe<LocaleStringType>;
  inverted?: Maybe<boolean>;
  ctas?: Maybe<CallToActionObjectType>;
  successImage?: Maybe<SanityImageObjectType>;
  mobileBackgroundImage?: Maybe<SanityImageObjectType>;
  formBackgroundImage?: Maybe<SanityImageObjectType>;
};

export const SignUpFormDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    analyticsIdentifier,
    ${LocaleString('launchButtonLabel')},
    ${LocaleString('launchLinkText')},
    ${LocaleString('cartPageLaunchButtonLabel')},
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${LocaleString('footnote')},
    contactSource,
    ${LocaleString('disclaimerText')},
    ${LocaleString('submitButtonText')},
    ${LocaleString('customText')},
    fields,
    ${LocaleString('successHeader')},
    ${LocaleString('successCopy')},
    ${LocaleString('successFootnote')},
    inverted,
    ${CallToActionObject('ctas[]')},
    ${SanityImageObject('successImage')},
    ${SanityImageObject('mobileBackgroundImage')},
    "formBackgroundImage": ${SanityImageObject('backgroundImage')},
  }
`;
