import { Anchor, SearchOption } from '@cyber-cats/uds/elements';

import { buildProductUrl } from 'utils/buildProductUrl';

import { SearchProductListItem } from './SearchProductListItem';
import { SearchOptionList } from './SearchOptionList';
import { SearchProductResult } from './types';

type SearchProductListProps = {
  heading: string;
  products: SearchProductResult[];
  onClick?: (product: SearchProductResult) => void;
};

export const SearchProductList = ({
  heading,
  products,
  onClick,
}: SearchProductListProps) => {
  // show maximum of 8 product suggestions
  const productsToRender = products.slice(0, 8);

  return (
    <SearchOptionList
      id="search-product-list"
      dataTestId="search-product-list"
      heading={heading}
      className="flex flex-col sm:grid sm:grid-cols-2"
    >
      {productsToRender.map(product => {
        const pdpUrl = buildProductUrl(product.masterId, product.name, {
          color: product.colorValue,
        });

        return (
          <li key={pdpUrl}>
            <SearchOption>
              <Anchor
                dataTestId="search-product-list-item"
                href={pdpUrl}
                onClick={() => onClick?.(product)}
                className="block hover:bg-none"
              >
                <SearchProductListItem product={product} />
              </Anchor>
            </SearchOption>
          </li>
        );
      })}
    </SearchOptionList>
  );
};
