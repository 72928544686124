import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { EmarsysScarab, ScarabCartItem } from 'utils/emarsysScarab';
import { CartItem } from '__generated__/graphql';

import { useAuth } from './useAuth';
import { useCart } from './useCart';
import { useOrder } from './useOrder';
import { useRouteChangeStart } from './useRouteChangeStart';
import { useSiteConfig } from './useSiteConfig';

export type UseEmarsysWebExtendReturn = {
  omitTrackingCommand: () => void;
  trackPdp: (productId: string) => void;
  trackCategory: (categoryId: string[]) => void;
  trackPurchase: (orderId: string, products: CartItem[]) => void;
  trackCartUpdate: () => void;
};

const EmarsysWebExtendContext = createContext<UseEmarsysWebExtendReturn>(
  {} as UseEmarsysWebExtendReturn
);

const transformCartItems = (cartItems: CartItem[]): ScarabCartItem[] => {
  const items: ScarabCartItem[] = [];

  for (const cartItem of cartItems) {
    if (cartItem.__typename === 'ProductCartItem') {
      items.push({
        item: cartItem.product.styleNumber,
        quantity: cartItem.quantity,
        price: cartItem.inCartPrice * cartItem.quantity,
      });
    }
  }

  return items;
};

export const EmarsysWebExtendProvider = ({ children }) => {
  const auth = useAuth();
  const router = useRouter();
  const { cart, cartResult } = useCart();
  const [order] = useOrder(router.query?.orderNo as string);
  const {
    emarsysMerchantId,
    env: { stage },
  } = useSiteConfig();

  const [shouldUpdateCart, setShouldUpdateCart] = useState(false);

  const scarab = useRef(
    new EmarsysScarab({
      disabled: !emarsysMerchantId,
      testMode: stage !== 'production',
    })
  );

  const clearOnPageChange = useCallback(
    (targetRoute: string) => {
      const pathname = targetRoute.split('?')[0];
      const currentPath = router.asPath.split('?')[0];

      if (pathname !== currentPath) {
        scarab.current.clear();
      }
    },
    [router.asPath]
  );

  // Clear the tracker state when the page changes
  // to reset the double-submit protection
  useRouteChangeStart(clearOnPageChange);

  useEffect(() => {
    if (auth.user?.email) {
      scarab.current.setUser(auth.user.email).safeSubmit();

      return;
    }

    if (!router.query?.orderNo) {
      const email = cart?.customer?.email || 'none';

      scarab.current.setUser(email).safeSubmit();

      return;
    }

    if (!order.fetching && !order.stale) {
      const email =
        order.data?.order?.customer?.email || cart?.customer?.email || 'none';

      scarab.current.setUser(email).safeSubmit();

      return;
    }
  }, [
    router.asPath,
    auth.user?.email,
    cart?.customer?.email,
    order.data?.order?.customer?.email,
    order.fetching,
    order.stale,
    router.query?.orderNo,
  ]);

  useEffect(() => {
    if (!cartResult.fetching && !cartResult.stale) {
      const cartItems = transformCartItems(
        (cart?.products || []) as CartItem[]
      );

      scarab.current
        .setCart(cartItems, { overwrite: shouldUpdateCart })
        .safeSubmit({ allowResubmit: shouldUpdateCart });

      if (shouldUpdateCart) {
        setShouldUpdateCart(false);
      }
    }
  }, [
    router.asPath,
    cartResult.fetching,
    cartResult.stale,
    cart?.products,
    shouldUpdateCart,
  ]);

  const emarsys: UseEmarsysWebExtendReturn = useMemo(
    () => ({
      omitTrackingCommand: () => {
        scarab.current.skipTracking().safeSubmit();
      },
      trackPdp: productId => {
        scarab.current.trackPdp(productId).safeSubmit();
      },
      trackCategory: categoryId => {
        scarab.current.trackCategory(categoryId).safeSubmit();
      },
      trackPurchase: (orderId, products) => {
        const purchase = transformCartItems(products);
        scarab.current.setCart([], { overwrite: true });

        if (purchase.length === 0) {
          scarab.current.skipTracking().safeSubmit();
        } else {
          scarab.current.trackPurchase(orderId, purchase).safeSubmit();
        }
      },
      trackCartUpdate: () => {
        setShouldUpdateCart(true);
      },
    }),
    []
  );

  return (
    <EmarsysWebExtendContext.Provider value={emarsys}>
      {children}
    </EmarsysWebExtendContext.Provider>
  );
};

export const useEmarsysWebExtend = () => {
  const ctx = useContext(EmarsysWebExtendContext);

  if (process.env.NODE_ENV === 'development' && !ctx) {
    throw new Error(
      'Please consume useEmarsysWebExtend inside the EmarsysWebExtendProvider'
    );
  }

  return ctx;
};
